import { useEffect } from 'react';
import getConfig from '../config';

const { BRANDING, MANIFEST_BACKGROUND_COLOR, MANIFEST_THEME_COLOR } = getConfig();

/** setTitle - Dynamic HTML title */
const setTitle = () => {
  document.title = BRANDING;
};

/** attachManifest - Dynamic manifest.json meta tag attachment within <head> */
const attachManifest = () => {
  const myDynamicManifest = {
    short_name: BRANDING,
    name: BRANDING,
    icons: [
      {
        src: 'icon.square.png',
        sizes: '32x32 24x24 16x16',
        type: 'image/png',
      },
      {
        src: 'logo.173x65.png',
        type: 'image/png',
        sizes: '173x65',
      },
    ],
    start_url: '.',
    display: 'standalone',
    theme_color: MANIFEST_THEME_COLOR,
    background_color: MANIFEST_BACKGROUND_COLOR,
  };
  const tag = document.createElement('link');
  tag.rel = 'manifest';
  const stringManifest = JSON.stringify(myDynamicManifest);
  tag.setAttribute(
    'href',
    `data:application/json;charset=utf-8,${encodeURIComponent(stringManifest)}`,
  );
  document.head.appendChild(tag);
};

/** attachThemeColor - Dynamic theme-color meta tag attachment within <head> */
const attachThemeColor = () => {
  const tag = document.createElement('link');
  tag.setAttribute('name', 'theme-color');
  tag.setAttribute('content', MANIFEST_THEME_COLOR);
  document.head.appendChild(tag);
};

/** Manifest - Dynamic manifest.json creation and <head> meta tag attachments */
export default function Manifest() {
  useEffect(() => {
    setTitle();
    attachManifest();
    attachThemeColor();
  }, []);
}
