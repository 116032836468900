import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Main from '../../layout/Main';
import CommunityGuidelines from './CommunityGuidelines';
import TermsOfUse from './TermsOfUse';
import CommunityStandards from './CommunityStandards';
import useScrollToHash from '../../hooks/useScrollToHash';
import classNames from '../../utils/classNames';
import styles from './index.module.scss';

/** Content - Page SubView aggregating content
 * @param {?Boolean} basic - when `true` renders without the Footer component
 */
const Content = ({ basic }) => {
  const location = useLocation();
  const layout = location.pathname.charAt(1) === '_' ? '_' : '';

  useScrollToHash({ hashRouter: true });

  return (
    <Main className={classNames(styles.Terms, 'content')} title="Terms legal" basic={basic} logo="dark">
      {/* SubNav: */}
      <ul className={styles.Legal}>
        <li>
          <HashLink smooth to={`/${layout}Terms#TermsOfUse`}>
            Terms of Use
          </HashLink>
        </li>
        <li>
          <HashLink smooth to={`/${layout}Terms#CommunityStandards`}>
            Community Standards
          </HashLink>
        </li>
      </ul>
      <hr />
      {/* Guidelines */}
      <CommunityGuidelines />
      <hr />
      {/* Legal Content: */}
      <TermsOfUse id="TermsOfUse" />
      <hr />
      <CommunityStandards id="CommunityStandards" />
    </Main>
  );
};

/** Terms - Page View */
export default function Terms() {
  return <Content />;
}

/** Terms - Page View sans navigation links */
export function TermsBasic() {
  return <Content basic />;
}
