import Card from './Card';
import classNames from '../../utils/classNames';
import { ReactComponent as AppStoreAndroid } from '../../assets/appstore-Android.min.svg';
import { ReactComponent as AppStoreIphone } from '../../assets/appstore-iPhone.min.svg';
import getConfig from '../../config';
import cardStyles from './Card.module.scss';
import styles from './AppStoreLinks.module.scss';

const { APPSTORE_URI_ANDROID, APPSTORE_URI_IOS } = getConfig();

const HEADING = 'Find us on iOS and Android';

/** AppStoreLinks - Component View for links/images for app stores
 * @param {string} heading
 */
export default function AppStoreLinks({ heading = HEADING }) {
  return (
    <Card className={classNames(cardStyles.Card, styles.AppStoreLinks)} heading={heading}>
      <div>
        <a
          href={APPSTORE_URI_IOS}
          target="appstore-iPhone"
          title="Get Squad for iPhone from the Apple App Store"
          aria-label="Squad for iPhone at the Apple App Store"
        >
          <AppStoreIphone className="appstore-iPhone" />
        </a>
        <a
          href={APPSTORE_URI_ANDROID}
          target="appstore-Android"
          title="Get Squad from Google Play Apps store for Android"
          aria-label="Squad at Google Play Apps store for Android"
        >
          <AppStoreAndroid className="appstore-Android" />
        </a>
      </div>
    </Card>
  );
}

export { HEADING };
