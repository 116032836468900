import { useForm, ValidationError } from '@formspree/react';
import NumberFormat from 'react-number-format';
import getConfig from '../../config';
import styles from './index.module.scss';

const { FORMSPREE_ID } = getConfig();

export default function ContactForm({
  context,
  preSubmissionContent,
  postSubmissionContent,
  textarea,
}) {
  const [state, handleSubmit] = useForm(FORMSPREE_ID);

  if (state.succeeded) {
    return postSubmissionContent || <p className={styles.succeeded}>Thanks - got it</p>;
  }

  return (
    <>
      {preSubmissionContent || null}
      <form className={styles.ContactForm} onSubmit={handleSubmit}>
        <input id="context" name="context" type="hidden" value={context} />
        <input
          id="name"
          name="name"
          type="text"
          required
          placeholder="Full name"
          autoComplete="off"
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
        <input
          id="business_name"
          name="business_name"
          type="text"
          required
          placeholder="Name of business"
          autoComplete="off"
        />
        <ValidationError prefix="Business Name" field="business_name" errors={state.errors} />
        <input
          id="email"
          name="email"
          type="email"
          required
          placeholder="name@email.com"
          autoComplete="off"
        />
        <ValidationError prefix="Input" field="email" errors={state.errors} />
        <NumberFormat
          // Mask module properties:
          format="+1 (###) ###-####"
          mask="_"
          allowEmptyFormatting
          // Input properties:
          id="phone"
          name="phone"
          type="tel"
          pattern="\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}"
          required
          autoComplete="off"
        />
        <ValidationError prefix="Input" field="phone" errors={state.errors} />
        {textarea ? (
          <>
            <textarea
              id="heard_about"
              name="heard_about"
              required
              placeholder={textarea}
              autoComplete="off"
            />
            <ValidationError prefix="Input" field="heard_about" errors={state.errors} />
          </>
        ) : null}
        <button type="submit" disabled={state.submitting}>
          RSVP
        </button>
      </form>
    </>
  );
}
