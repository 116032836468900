import { useEffect, useState } from 'react';

/** useScrollPosition - Track the X/Y window scroll offset
 * @param {?string} axis - Defaults to "Y" (vertical), also allows "X" to track horizontal scrolling
 * @returns {Number}
 */
export default function useScrollPosition({ axis = 'Y' /* pass "X" or "Y" (UPPER-CASE) */ } = {}) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window[`page${axis}Offset`]);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, [axis]);

  return scrollPosition;
}
