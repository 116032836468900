import classNames from '../../utils/classNames';
import cardStyles from './Card.module.scss';

/** Card - Component View card content template with basic banner-mode
 * @param {?Boolean} isBanner - Returns `heading` in <p> (paragraph content)
 * @param {string} id
 * @param {?string} className
 * @param {string} heading
 * @param {{ children: JSX.Element}}
 */
export default function Card({ isBanner, id, className, heading, children }) {
  return isBanner ? (
    <p>{heading}</p>
  ) : (
    <div id={id} className={classNames(cardStyles.Card, className)} data-role="card">
      <span />
      <h4>{heading}</h4>
      {children}
    </div>
  );
}
