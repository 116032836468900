import { Link, useLocation } from 'react-router-dom';
import ContactEmail from '../../components/ContactEmail';
import ContactPhone from '../../components/ContactPhone';
import scrollToTop from '../../utils/scrollToTop';
import getConfig from '../../config';
import './PrivacyPolicy.global.scss';

const { BASE_URI, LEGAL_ABBRV, LEGAL_NAME } = getConfig();

/** PrivacyPolicy - Page SubView for "Privacy Policy" (exported to HTML via .docx)
 * 1. Remove leading <html>, <head>, </head>, <meta> and </html> tags
 * 2. Change <body class> to <div class title="">" (keep classes)
 * 3. Move <style> tag content into PrivacyPolicy.global.scss
 * 4. Replace-All "class=" --> "className="
 * 5. Replace heading <p>+<span> with <h1>
 */
export default function PrivacyPolicy({ id = null }) {
  const location = useLocation();
  const layout = location.pathname.charAt(1) === '_' ? '_' : '';
  const PRIVACY_PG_LINK = `${BASE_URI}/#/${layout}Privacy`;

  return (
    <article className="PrivacyPolicy" id={id} title="Privacy Policy legal">
      <div className="c25" title="">
        <h1>{LEGAL_ABBRV} Privacy Policy</h1>
        <p className="c4">
          <span className="c16">Last modified: January 21, 2022 </span>
        </p>
        <p className="c4">
          <span className="c0">Introduction</span>
        </p>
        <p className="c4">
          <span className="c3">
            {LEGAL_NAME} (&quot;{LEGAL_ABBRV}&quot; or &quot;we&quot; or &ldquo;us&rdquo; or
            &ldquo;our&rdquo;) respects your privacy and is committed to protecting it through Our
            compliance with this policy. This policy describes:
          </span>
        </p>
        <ul className="c18 lst-kix_list_4-0 start">
          <li className="c22 c11 li-bullet-0">
            <span className="c3">
              The types of information we may collect or that you may provide when you download and
              register , access or use, or transact with the {LEGAL_ABBRV} application or associated
              website (www.squadeyh.com), any associated content, material, or functionality
              contained on the {LEGAL_ABBRV} application, all interactive features, applications,
              widgets, blogs, social networks and social network pages, and other online or wireless
              offerings that post a link to or otherwise is covered by this Privacy Policy, whether
              accessed via computer, mobile device or other technology or any associated content,
              material, or functionality contained on the {LEGAL_ABBRV} application (collectively,
              the &ldquo;App&rdquo;). The App is owned by {LEGAL_ABBRV}.
            </span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">
              Our practices for collecting, using, maintaining, processing, accessing, storing,
              protecting and disclosing that information.
            </span>
          </li>
          <li className="c12 c11 li-bullet-0">
            <span className="c3">Your rights under applicable law.</span>
          </li>
        </ul>
        <p className="c4">
          <span className="c16">Information We Collect and How We Collect It</span>
        </p>
        <p className="c4">
          <span className="c3">
            The below chart summarizes our collection, use, and disclosure of personal information:
          </span>
        </p>
        {/* <a id="t.b10644f661202e7bb54594b3b98c9e30056a05ff" />
        <a id="t.0" /> */}
        <table className="c24">
          <tbody>
            <tr className="c1">
              <td className="c5 c19" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c0">Types of Personal Information Collected</span>
                </p>
              </td>
              <td className="c17" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c0">Source of the Personal Information </span>
                </p>
              </td>
              <td className="c14 c19" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c0">Purpose for Collection and Use</span>
                </p>
              </td>
              <td className="c8 c19" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c0">
                    Information Disclosed for a Business or Commercial Purpose? (Yes/No)
                  </span>
                </p>
              </td>
              <td className="c10 c19" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c0">
                    Categories of Third Parties (Which Do Not Include Service Providers) with whom
                    the Personal Information may be Shared under certain circumstances.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c15">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Identifiers (e.g., name, e-mail, postal address, persistent identifiers that we
                    assign, and phone number)
                  </span>
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Information that you provide directly to us through the App; from business
                    partners; and from our service providers that support the App&rsquo;s
                    operations.
                  </span>
                </p>
              </td>
              <td className="c14" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Operate the App and associated services such as making certain products and
                    services available; Prevent fraud; Conduct surveys; Manage our business
                    operations including legal compliance; Market products and services; Resolve
                    complaints; Establish and defend legal rights.
                  </span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
              </td>
              <td className="c8" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Yes</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Business partners who deliver product and service offerings and financial
                    institutions.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c15">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Demographic information</span>
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span>
                    Information that you provide directly to us through the App; from business
                    partners; and from our service providers that support the App&rsquo;s
                    operations.
                  </span>
                </p>
              </td>
              <td className="c14" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Operate the App and associated services such as making certain products and
                    services available; Prevent fraud; Conduct surveys; Manage our business
                    operations including legal compliance; Market products and services; Resolve
                    complaints; Establish and defend legal rights.
                  </span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
              </td>
              <td className="c8" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Yes</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Business partners who deliver product and service offerings and financial
                    institutions.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c15">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Financial information and account details</span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Information that you provide directly to us through the App and from our service
                    providers that support the App&rsquo;s operations.
                  </span>
                </p>
              </td>
              <td className="c14" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Operate the App and associated services such as making certain products and
                    services available; Prevent fraud; Conduct surveys; Manage our business
                    operations including legal compliance; Market products and services; Resolve
                    complaints; Establish and defend legal rights.
                  </span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
              </td>
              <td className="c8" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Yes</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Business partners who deliver product and service offerings and financial
                    institutions.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c15">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Geolocation information</span>
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Information that you provide directly to us through the App; from business
                    partners; and from our service providers that support the App&rsquo;s
                    operations.
                  </span>
                </p>
              </td>
              <td className="c14" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Operate the App and associated services such as making certain products and
                    services available; Prevent fraud; Conduct surveys; Manage our business
                    operations including legal compliance; Market products and services; Resolve
                    complaints; Establish and defend legal rights.
                  </span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
              </td>
              <td className="c8" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Yes</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Business partners who deliver product and service offerings and financial
                    institutions.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c15">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Commercial information (e.g., purchase, review, or comment history)
                  </span>
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Information that you provide directly to us through the App; from business
                    partners; and from our service providers that support the App&rsquo;s
                    operations.
                  </span>
                </p>
              </td>
              <td className="c14" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Operate the App and associated services such as making certain products and
                    services available; Prevent fraud; Conduct surveys; Manage our business
                    operations including legal compliance; Market products and services; Resolve
                    complaints; Establish and defend legal rights.
                  </span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
              </td>
              <td className="c8" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Yes</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Business partners who deliver product and service offerings and financial
                    institutions.
                  </span>
                </p>
              </td>
            </tr>
            <tr className="c15">
              <td className="c5" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Browsing activity, device information, and Usage Details
                  </span>
                </p>
              </td>
              <td className="c23" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">
                    Web tracking technology such as browser cookies, flash cookies, and web beacons
                    and information about your collection on the App.
                  </span>
                </p>
              </td>
              <td className="c14" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">App functionality, customization and security.</span>
                </p>
                <p className="c2 c9">
                  <span className="c3" />
                </p>
                <p className="c2">
                  <span className="c3">Advertising and marketing.</span>
                </p>
              </td>
              <td className="c8" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Yes</span>
                </p>
              </td>
              <td className="c10" colSpan="1" rowSpan="1">
                <p className="c2">
                  <span className="c3">Third party web tracking providers.</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="c2 c9">
          <span className="c3" />
        </p>
        <p className="c2">
          <span className="c16">Video and Audio Data.</span>
        </p>
        <p className="c2 c9">
          <span className="c3" />
        </p>
        <p className="c2">
          <span className="c3">
            When you subscribe to or use {LEGAL_ABBRV}&rsquo;s video- or audio-based Services, you
            are enabling us, and certain of our third-party service providers, to record, store,
            view and process video and/or audio recordings from your device or video equipment. This
            may include capturing and sending you portions of this data through our services or
            analyzing the data to identify and translate speech data or performing other functions.
            We may process information from your monitoring equipment so that we can send you
            alerts.
          </span>
        </p>
        <p className="c2 c9">
          <span className="c3" />
        </p>
        <p className="c2">
          <span className="c3">
            Data protection and privacy laws in your jurisdiction may impose certain
            responsibilities on you and your use of video recording equipment and other devices with
            recording capabilities. You (not {LEGAL_ABBRV}) are responsible for ensuring that you
            comply with any applicable laws. For example, you may need to display a notice that
            alerts employees, customers, subcontractors, and other third parties that they may be
            photographed or recorded and that the photographs or recordings may be shared with other
            parties. {LEGAL_ABBRV} takes no responsibility for your compliance with these laws.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Other Information Collection Technologies.</span>
        </p>
        <p className="c4">
          <span className="c3">
            The technologies we use for automatic information collection may include:
          </span>
        </p>
        <ul className="c18 lst-kix_list_5-0 start">
          <li className="c22 c11 li-bullet-0">
            <span className="c3">
              Cookies. A cookie is a small file placed on your smartphone or browser. It may be
              possible to refuse to accept mobile cookies by activating the appropriate setting on
              your browser or smartphone. However, if you select this setting you may be unable to
              access certain parts of our App.
            </span>
          </li>
          <li className="c12 c11 li-bullet-0">
            <span className="c3">
              Web Beacons. Pages of the App may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags and single-pixel gifs) that permit
              {LEGAL_ABBRV}, for example, to count users who have visited those pages and for other
              related App statistics (for example, recording the popularity of certain App content
              and verifying system and server integrity).
            </span>
          </li>
        </ul>
        <p className="c4">
          <span className="c0">Third-party Information Collection</span>
        </p>
        <p className="c4">
          <span className="c3">
            When you use the App, certain third parties may use automatic information collection
            technologies to collect information about you or your device. Those third parties may
            have their own privacy practices and policies. These third parties may include but are
            not limited to:
          </span>
        </p>
        <ul className="c18 lst-kix_list_6-0 start">
          <li className="c22 c11 li-bullet-0">
            <span className="c3">Advertisers, ad networks and ad servers.</span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">
              Analytics (including, but not limited to, marketing analytics) and digital platform
              management companies.
            </span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">Your mobile device manufacturer.</span>
          </li>
          <li className="c12 c11 li-bullet-0">
            <span className="c3">Your mobile service provider.</span>
          </li>
        </ul>
        <p className="c4">
          <span className="c0">Other Disclosures of Your Information</span>
        </p>
        <p className="c4">
          <span className="c3">
            In addition to the disclosures described above, we may disclose personal information
            that we collect or you provide:
          </span>
        </p>
        <ul className="c18 lst-kix_list_7-0 start">
          <li className="c22 c11 li-bullet-0">
            <span className="c3">
              To our affiliates, group companies, holding companies, located within and outside the
              US.
            </span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">
              To a buyer or other successor in interest in the event of a merger, divestiture,
              restructuring, reorganization, dissolution or other sale or transfer of some or all of
              {LEGAL_ABBRV}, LLC&rsquo;s assets, whether as a going concern or as part of
              bankruptcy, liquidation or similar proceeding, in which personal information held by
              us about our App users is among the assets transferred.
            </span>
          </li>
          <li className="c12 c11 li-bullet-0">
            <span className="c3">
              To comply with any court order, investigation, law or legal process, including,
              without limitation, to respond to any government or regulatory request.
            </span>
          </li>
        </ul>
        <p className="c4">
          <span className="c3">
            The App will also allow you to intentionally interact with third parties, such as those
            whose businesses, products, or services are reviewed, advertised, or made available
            through the App.
          </span>
        </p>
        <p className="c4">
          <span className="c3">
            We do not necessarily control third parties&#39; collection or use of your information
            to serve interest-based advertising. However, these third parties may provide you with
            ways to choose not to have your information collected or used in this way. You can opt
            out of receiving targeted ads via certain advertising alliances; for example, you
            generally may opt out of targeted ads from members of the Network Advertising Initiative
            (&quot;NAI&quot;) on the NAI&#39;s website.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Children under the Age of 16</span>
        </p>
        <p className="c4">
          <span className="c3">
            The App is not intended for children under 16 years of age, and we do not knowingly
            collect personal information from children under 16. If we learn we have collected or
            received personal information from a child under 16, we will delete that information to
            the best of our ability. Please be aware that we may not be able to fully delete certain
            user information if a user&rsquo;s account remains open. If you believe we might have
            any information from or about a child under 16, please contact us at the e-mail address
            provided to you below.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Do Not Track</span>
        </p>
        <p className="c4">
          <span className="c3">
            We treat the information of everyone who comes to our App in accordance with this
            Privacy Policy. We do not monitor or respond to any &ldquo;Do Not Track&rdquo; setting
            that may be available through certain browsers.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Information Security</span>
        </p>
        <p className="c4">
          <span className="c3">
            We have implemented measures designed to secure your personal information against
            accidental loss and from unauthorized access, use, alteration and disclosure.
            Unfortunately, the transmission of information via the internet and mobile platforms is
            not completely secure. Although we do our best to protect your personal information, we
            cannot guarantee the security of your personal information transmitted through our App.
            Any transmission of personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures we provide.
          </span>
        </p>
        <p className="c4">
          <span>
            If you receive a suspicious email on our behalf, we suggest you do not reply to it and
            do not open any attachments, click on links contained in the e-mail or provide any
            information in response to it. You can report suspected fraud involving us by sending an
            e-mail to{' '}
          </span>
          <ContactEmail subject="phishing" />
          <span className="c3">
            . Please include the word &quot;phishing&quot; in the subject line of your e-mail and
            provide as much information as possible about the fraudulent activity or message. We do
            not send e-mails requesting a user for payment information, username or passwords.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Online Account Management</span>
        </p>
        <p className="c26">
          <span className="c3">
            If you elected to subscribe to any online services provided via the App, including,
            without limitation, the ability to access any password-protected areas of the App, you
            will be required to submit personal information for the purpose of verifying your
            identity and your authority to manage the account (&ldquo;Access Credential
            Information&rdquo;). This Access Credential Information will be used exclusively by
            {LEGAL_ABBRV}, our agents, suppliers and third-party service providers for internal use
            and to respond to your service requests.
          </span>
        </p>
        <p className="c4">
          <span className="c16">Sweepstakes, Promotions, Contests &amp; Surveys</span>
        </p>
        <p className="c4">
          <span className="c3">
            Through the App, you may wish to participate in certain online polls, surveys, contests,
            sweepstakes and other promotions that may be offered from time to time by us, a partner,
            or other entity. Participation in these polls, surveys, contests, sweepstakes and
            promotions is completely voluntary; you have a choice about whether or not to disclose
            information required to enter. Information requested may include data such as your name,
            address, date of birth, phone number, mobile phone number, e-mail address, username,
            and/or similar information. Through these online polls, surveys and promotions, you may
            choose to participate in activities such as sharing information with others and sending
            email invitations. In connection with any online polls, surveys, contests, sweepstakes
            and other promotions that may be offered from time to time via the App, we use the
            information you provide to administer such the polls, surveys, contests, sweepstakes and
            other promotions. Subject to applicable contractual or legal restrictions, we also may
            use the information to communicate with you, or the other people you select, about our
            services or products. Any information that you submit to one of our partners or another
            entity through the App in the course of any poll, survey, contest, sweepstakes, or
            promotion offered by any third party shall be fully at your own risk, and {LEGAL_ABBRV}{' '}
            hereby disclaims all responsibility for any losses, liability, damages, or expenses that
            may arise from your submission of such information.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Changes to Our Privacy Policy</span>
        </p>
        <p className="c4">
          <span className="c3">
            We may update these Terms of Use from time to time and may amend them at any time to
            incorporate additional terms specific to additional features, applications,
            opportunities, or services that we may make available on or through the App. All such
            updates and amendments are effective immediately upon notice thereof, which we may give
            by any means, including, but not limited to, by posting a revised version of these Terms
            of Use.
          </span>
        </p>
        <p className="c4">
          <span className="c20 c16 c21">
            You should review this Privacy Policy often to stay informed of changes that may affect
            you, as your continued use of the App signifies your continuing consent to this Privacy
            Policy.
          </span>
        </p>
        <p className="c4">
          <span>
            The most recent version of the Privacy Policy is reflected by the effective date
            specified in this Privacy Policy. The version of this Privacy Policy posted at{' '}
          </span>
          <Link to={`/${layout}Privacy`} onClick={() => scrollToTop()}>
            {PRIVACY_PG_LINK}
          </Link>
          <span className="c3">
            {' '}
            on each respective date you use the App shall be the Privacy Policy applicable to your
            access and use of the App on that date. Our electronically or otherwise properly stored
            copies of this Privacy Policy shall be deemed to be the true, complete, valid,
            authentic, and enforceable copy of the version of this Privacy Policy which were in
            force on each respective date you used the App.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Contact Information</span>
        </p>
        <p className="c4">
          <span>
            To ask questions or comment about this Privacy Policy and our privacy practices, contact
            us at:{' '}
          </span>
          <ContactEmail />
          <span>
            {' '}
            or at <ContactPhone />.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Your California Privacy Rights</span>
        </p>
        <p className="c2">
          <span className="c3">
            If you are a California resident and a &ldquo;consumer&rdquo; as defined under the
            California Consumer Privacy Act, including the California Privacy Rights Act and any
            successor legislation and associated regulations (collectively, the &ldquo;CCPA&rdquo;),
            then the CCPA may provide you with specific rights regarding your personal information.
          </span>
        </p>
        <p className="c2 c9">
          <span className="c3" />
        </p>
        <p className="c2">
          <span className="c3">
            You have the right to request that businesses subject to the CCPA disclose certain
            information to you about their collection and use of your personal information over the
            past 12 months. In addition, you have the right to ask such businesses to delete
            personal information collected for you, subject to certain exceptions. If you choose to
            exercise your rights, you also have the right not to be discriminated against and will
            not be charged different prices or provided different quality of services.
          </span>
        </p>
        <p className="c2 c9">
          <span className="c3" />
        </p>
        <p className="c2">
          <span>
            {LEGAL_ABBRV} may not delete some or all requested personal information as allowed or
            required by applicable law. Moreover, please be aware that we may not be able to fully
            delete certain user information if the user&rsquo;s account remains active.
          </span>
          <span>We do not sell personal information to third parties.</span>
        </p>
        <p className="c4">
          <span className="c3">
            You may request to receive details about how we collect, use, and share your personal
            information. Specifically, you may request to receive the specific pieces of personal
            information that we have collected about you.
          </span>
        </p>
        <p className="c4">
          <span className="c3">You may also request to receive:</span>
        </p>
        <ul className="c18 lst-kix_list_8-0 start">
          <li className="c11 c22 li-bullet-0">
            <span className="c3">
              the categories of personal information that we have collected about you,
            </span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">
              the categories of personal information that we have disclosed for a business purpose,
            </span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">
              the categories of sources from which we collected the personal information,
            </span>
          </li>
          <li className="c2 c11 li-bullet-0">
            <span className="c3">our purposes for collecting that personal information, and</span>
          </li>
          <li className="c11 c12 li-bullet-0">
            <span className="c3">
              the categories of parties with whom we share your personal information.
            </span>
          </li>
        </ul>
        <p className="c2">
          <span className="c3">
            We do allow third parties to collect personal information through our Services and share
            personal information with third parties for the business purposes described in this
            Privacy Policy.
          </span>
        </p>
        <p className="c4" id="h.gjdgxs">
          <span>You can exercise your applicable CCPA rights by contacting us at </span>
          <ContactEmail />
          <span>
            {' '}
            or at <ContactPhone />.
          </span>
        </p>
        <p className="c2">
          <span className="c3">
            Please note that the CCPA requires us to verify the requests we receive from you when
            you exercise certain of the rights listed above. We may need more information when we
            receive your request before taking further action in order to verify your identity. You
            may also authorize an agent to make a request on your behalf, who will need to provide
            similar information for verification.
          </span>
        </p>
      </div>
    </article>
  );
}
