import contactStyles from '../../styles/ContactType.module.scss';

const GENERAL_CONTACT_PHONE = { countryCode: '+1', areaCode: '404', number: '815-6500' };

/** ContactPhone - Component View for telephone link UX consistency and accessibility
 * @param {Object} phone - { countryCode{string}, areaCode{string}, number{string} }
 */
export default function ContactPhone({ phone = GENERAL_CONTACT_PHONE }) {
  const callable = `${phone.countryCode}${phone.areaCode}-${phone.number}`;
  const friendly = `${phone.countryCode} (${phone.areaCode}) ${phone.number}`;

  return (
    <a href={`tel:${callable}`} title={`Call: ${friendly}`} aria-label="Telephone Number">
      <span className={contactStyles.ContactType} itemProp="telephone">
        {friendly}
      </span>
    </a>
  );
}
