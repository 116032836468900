/* eslint-disable no-restricted-globals */
import { Link, HashRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from './router';
import useBranding from './hooks/useBranding';
import useRedirect from './hooks/useRedirect';
import { AppLogo } from './layout/Main';
import { ReactComponent as ManFacepalming } from './assets/man-facepalming-dark-skin-tone.min.svg';
import errorStyles from './styles/Error.module.scss';
import './styles/App.global.scss';

const queryClient = new QueryClient();

/** AppWrapper - Component SubView wrapping required Providers
 * @param {ReactChildren} children
 */
const AppWrapper = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>
);

/** ErrorFallback - Page SubView for generic error handling functionality
 * @param {Object} error
 * @param {Function} resetErrorBoundry
 * @returns {JSXElement}
 */
const ErrorFallback = ({
  error = { message: 'Minor error, not the end of the world' },
  resetErrorBoundary,
}) => (
  <AppWrapper>
    <Router>
      <article role="alert" className={errorStyles.Error}>
        <a href="/" alt="Back to Home">
          <AppLogo />
        </a>
        <ManFacepalming className={errorStyles.PersonIllustration} />
        <div data-role="nowrap">
          <p>
            <em>Somethin's up | We're on it</em>
          </p>
        </div>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary} type="button">
          Try again
        </button>
      </article>
    </Router>
  </AppWrapper>
);

/** resetAppState - Reset the state of your app so the error doesn't happen again */
const resetAppState = () => {
  // ...
};

/** App - Main Container */
export default function App() {
  useRedirect();
  useBranding();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetAppState}>
      <AppWrapper>
        <AppRouter />
      </AppWrapper>
    </ErrorBoundary>
    /** To test the ErrorFallback component rendering, comment above and uncomment below */
    // <ErrorFallback error={{message:'Error message text'}} resetErrorBoundary={()=>{}} />
  );
}

export { ErrorFallback }
