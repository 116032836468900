import Main from '../../layout/Main';
import SupportDetails from './SupportDetails';
// import useScrollToHash from '../../hooks/useScrollToHash';
import styles from './index.module.scss';

/** Content - Page SubView aggregating content
 * @param {?Boolean} basic - when `true` renders without the Footer component
 */
const Content = ({ basic }) => (
  // useScrollToHash({ hashRouter: true }); // If there are page anchors (see Terms pg)
  <Main className={styles.Support} title="Support info" basic={basic} logo="dark">
    <SupportDetails />
  </Main>
);

/** Support - Page View - NAV NOT NEEDED (uses view below as `default`) */
export default function Support() {
  return <Content />;
}

/** SupportBasic - Page View sans navigation links */
export function SupportBasic() {
  return <Content basic />;
}
