// import { Link, useLocation } from 'react-router-dom';
import ContactForm from '../../components/ContactForm';
import contactFormStyles from '../../components/ContactForm/index.module.scss';
import styles from './RSVPForm.module.scss';

const POST_SUBMISSION_CONTENT = (
  <p className={contactFormStyles.succeeded}>👍🏿 Thanks, we got your RSVP</p>
);

export default function RSVPForm({
  id = null,
  context,
  heading,
  subheading,
  preSubmissionContent,
  postSubmissionContent,
  textarea,
}) {
  // const location = useLocation();
  // const layout = location.pathname.charAt(1) === '_' ? '_' : '';

  // Placeholder until we get official page-content from SQUADJJ team

  return (
    <article className={styles.RSVPForm} id={id} title="RSVP form">
      <div title="">
        <h1>{heading}</h1>
        {subheading ? <h2>{subheading}</h2> : null}

        <ContactForm
          context={context}
          preSubmissionContent={preSubmissionContent}
          postSubmissionContent={postSubmissionContent || POST_SUBMISSION_CONTENT}
          textarea={textarea}
        />
      </div>
    </article>
  );
}
