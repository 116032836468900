/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';

/** useRedirect - Catch all incoming pathnames and their query-params and send through HashRouter */
export default function useRedirect() {
  useEffect(() => {
    const { pathname, search } = location;
    const cleanPath = pathname.replace('/jupiter-marketing-web', '').replace('/#/', '/');
    if (cleanPath.trim() !== '/') location.href = `/#${cleanPath}${search}`;
  }, []);
}
