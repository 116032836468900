/* eslint-disable no-restricted-globals */
import { AppStoreLinks } from '../../components/Cards';
import styles from './GetTheAppContent.module.scss';

export default function GetTheAppContent({ id = null }) {
  const { hash = '' } = location;
  // Parse query params
  const params = hash.split('?')[1]
    ? hash
        .split('?')[1]
        .split('&')
        .map((qp) => ({ [qp.split('=')[0]]: qp.split('=')[1] }))
    : [];
  // console.log('params', params);
  // Clear from the address bar/URI
  location.hash = hash.split('?')[0];

  return (
    <article className={styles.GetTheAppContent} id={id} title="GetTheApp content">
      <div title="">
        <AppStoreLinks />
      </div>
    </article>
  );
}
