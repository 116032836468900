import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import getConfig from '../../config';
import scrollToTop from '../../utils/scrollToTop';
import './CommunityStandards.global.scss';

const { BASE_URI } = getConfig();

/** CommunityStandards - Page SubView for "Community Standards" (exported to HTML from .docx)
 * 1. Remove leading <html>, <head>, </head>, <meta> and </html> tags
 * 2. Change <body class> to <div class title="">" (keep classes)
 * 3. Move <style> tag content into CommunityStandards.global.scss
 * 4. Replace-All "class=" --> "className="
 * 5. Replace heading <span> with <h1>
 */
export default function CommunityStandards({ id = null }) {
  const location = useLocation();
  const layout = location.pathname.charAt(1) === '_' ? '_' : '';
  const TERMSOFUSE_HASH_LINK = `${BASE_URI}/#/${layout}Terms#TermsOfUse`;
  const PRIVACY_PG_LINK = `${BASE_URI}/#/${layout}Privacy`;

  return (
    <article className="CommunityStandards" id={id} title="Community Standards legal">
      <div className="c7" title="">
        <h1>SQUADJJ,LLC COMMUNITY STANDARDS</h1>
        <p className="c4">
          <span className="c11 c9 c15" />
        </p>
        <p className="c5">
          <span className="c9 c11">
            By using our App or website and associated Services (collectively
            &ldquo;Services&rdquo;), you agree to these Community Standards established by SquadJJ,
            LLC (&ldquo;SquadJJ&rdquo;)
          </span>
          <span className="c9">. &nbsp;</span>
          <span>
            You represent and warrant that you have read, agree with, and have the authority to bind
            yourself and any entity on whose behalf you use our App, website and associated Services
            (collectively &ldquo;You&rdquo;) to our Terms of Use, available at{' '}
          </span>
          <HashLink smooth to={`/${layout}Terms#TermsOfUse`}>
            {TERMSOFUSE_HASH_LINK}
          </HashLink>
          <span>, and our Privacy Policy, available at </span>
          <Link to={`/${layout}Privacy`} onClick={() => scrollToTop()}>
            {PRIVACY_PG_LINK}
          </Link>
          <span>
            . &nbsp;Terms capitalized but not defined herein have the meaning assigned to them in
            the SQUADJJ Terms of Use and Privacy Policy referenced above.
          </span>
        </p>
        <p className="c4">
          <span className="c13 c9" />
        </p>
        <p className="c5">
          <span className="c0">
            You acknowledge and agree that you and each person who has access to or uses the
            Services as your agent or otherwise on your behalf, (collectively, &ldquo;Users&rdquo;)
            shall:
          </span>
        </p>
        <p className="c2">
          <span className="c0" />
        </p>
        <ol className="c1 lst-kix_list_4-0 start" start="1">
          <li className="c3 c8 li-bullet-0">
            <span className="c0">
              treat our Services with respect, and refrain from any conduct that may damage or
              destroy such Services. In the event any element of the Services is damaged in
              connection with an account under your care, custody or control, you will fully
              reimburse SQUADJJ for all costs, expenses, damages, liabilities and losses, including
              without limitation any attorneys&rsquo; fees, incurred by SQUADJJ in connection with
              any such damage or destruction.{' '}
            </span>
          </li>
        </ol>
        <p className="c2 c3">
          <span className="c0" />
        </p>
        <ol className="c1 lst-kix_list_4-0" start="2">
          <li className="c3 c10 li-bullet-0">
            <span className="c0">
              use the Services for lawful purposes and in accordance with applicable law.
              &nbsp;Users &nbsp;are prohibited from storing, distributing or transmitting any
              unlawful material through the Services. &nbsp;You shall cause all Users to acknowledge
              that storing, distributing or transmitting unlawful material could expose you and such
              User to criminal and/or civil liability and that, if a third-party claims that
              material that you or any User has created using the Services are unlawful, you and
              such User will bear the burden of establishing that it is lawful. You agree, and shall
              cause all Users to acknowledge and agree that all materials publicly posted or
              privately transmitted on or through the Services are the sole responsibility of the
              User, not SQUADJJ, and that the User is responsible exclusively for all material that
              the User uploads, posts or otherwise transmits to or through the Services.
            </span>
          </li>
          <li className="c8 c3 li-bullet-0">
            <span className="c0">
              at all times conduct yourself with due regard for the public conventions and morals,
              as determined by SQUADJJ, in its sole discretion. Without limiting the generality of
              the foregoing, you shall not do or commit any act, omission, or other thing that, in
              SQUADJJ&rsquo;s sole discretion, will tend to degrade SQUADJJ in society or bring
              SQUADJJ into public hatred, public disrepute, contempt, scorn, or ridicule, or that
              will tend to shock, insult or offend the community or public morals or decency or
              prejudice SQUADJJ or its public image in general.
            </span>
          </li>
        </ol>
        <p className="c2 c3">
          <span className="c0" />
        </p>
        <ol className="c1 lst-kix_list_4-0" start="4">
          <li className="c10 c3 li-bullet-0">
            <span className="c0">
              shall bear every and all risk and consequence, legal, commercial, or otherwise, for
              all communications that are sent or received via your account and any actions taken by
              any User in breach of the terms of these Community Standards and the SQUADJJ Terms of
              Use.
            </span>
          </li>
          <li className="c10 c3 li-bullet-0">
            <span className="c0">
              acknowledge and undertake no act or omission to deny or refuse that SQUADJJ reserves
              the right to monitor the use of the Services and to investigate suspected violations
              of these Community Standards. &nbsp;SQUADJJ may seek to gather information from you or
              the User who is suspected of violating these Community Standards, and from any other
              User as SQUADJJ deems warranted in its sole discretion. &nbsp;If SQUADJJ believes, in
              its sole discretion, that a violation of these terms has occurred, it may edit or
              modify any submission, posting or e-mails, remove the material permanently, cancel
              postings, warn other users or take other corrective action it deems appropriate.
              SQUADJJ will, in its sole discretion, fully cooperate with any law enforcement
              authorities or court order requesting or directing SQUADJJ to disclose the identity of
              anyone posting any e-mails or publishing or otherwise making available any materials
              that are believed to violate these terms.{' '}
            </span>
          </li>
          <li className="c10 c3 li-bullet-0">
            <span className="c0">
              acknowledge, and undertake no act or omission to deny or refuse that the App and
              Services are and shall at all times remain the property of SQUADJJ. &nbsp;Subject to
              compliance with these Community Standards, the SQUADJJ Terms of Use, the SQUADJJ
              Privacy Policy, and all applicable third party license restrictions, SQUADJJ hereby
              grants a royalty-free, non-exclusive, non-transferrable, fully revocable license and
              right to use the Services. Except for the foregoing license right, all ownership
              rights, title, and interest in the App and Services will remain the exclusive property
              of SQUADJJ.
            </span>
          </li>
          <li className="c10 c3 li-bullet-0">
            <span className="c0">
              not to send, create or reply to so called &quot;mailbombs&quot; (i.e., emailing copies
              of a single message to many users, or sending large or multiple files or messages to a
              single user with malicious intent) or engage in &quot;spamming&quot; (i.e.,
              unsolicited emailing for business or other purposes) or undertake any other activity
              which may adversely affect the operation or enjoyment of the Services by any other
              person. You may not, and may not allow Users to, reproduce, sell, resell or otherwise
              exploit any resource, or access to any resource, contained on the Services.
            </span>
          </li>
          <li className="c10 c3 li-bullet-0">
            <span className="c0">
              not send messages or e-mails or submit to or publish through profile pages, bulletin
              boards, chat rooms, community threads or other fora or otherwise make available on or
              through the Services any content, or act in a way, which in Our sole discretion and
              opinion:
            </span>
          </li>
        </ol>
        <ol className="c1 lst-kix_list_4-1 start" start="1">
          <li className="c6 li-bullet-0">
            <span className="c0">
              libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive,
              harassing, threatening or offensive;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              disparages, criticizes, belittles, parodies or otherwise portrays in a negative light
              any other person or entity;{' '}
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              seeks to exploit or harm children by exposing them to inappropriate content, asking
              for personally identifiable details or otherwise;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              infringes any intellectual property or other right of any entity or person, including
              without limitation violating anyone&#39;s copyrights or trademarks or their rights of
              publicity;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">violates any law or advocates or promotes illegal activity;</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              includes transmissions of information restricted under any contractual or other
              relationship (e.g., inside information, proprietary or confidential information
              received in the context of an employment or a non-disclosure agreement);
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              impersonates or misrepresents User or otherwise manipulates headers or identifiers to
              disguise the origin of the content;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              &nbsp;advertises any commercial endeavor (e.g., offering for sale products or
              services) or otherwise engages in any commercial activity (e.g., conducting raffles or
              contests, displaying sponsorship banners, and/or soliciting goods or services) except
              as specifically authorized by SQUADJJ;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">solicits funds, advertisers or sponsors;</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              includes programs which contain viruses, worms and/or &#39;Trojan horses&#39; or any
              other computer code, files or programs designed to interrupt, overload, collapse,
              destroy or limit the functionality of any computer software or hardware or
              telecommunications;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              disrupts the normal flow of dialogue, causes a screen to scroll faster than other
              users are able to type, or otherwise act in a way which affects the ability of other
              people to engage in real time activities using the Services;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">includes files of unsupported formats;</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">amounts to a &#39;pyramid&#39; or similar scheme</span>
          </li>
          <li className="c6 li-bullet-0" id="h.30j0zll">
            <span className="c0">
              amounts to &#39;data warehousing&#39; (i.e., using any web space made available to you
              or Users as storage for large files or large amount of data which are only linked from
              other sites); or
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              contains links to other sites that contain the kind of content, which falls within the
              descriptions set out at (i) to (xiv) above.
            </span>
          </li>
        </ol>
        <ol className="c1 lst-kix_list_4-0" start="9">
          <li className="c10 c3 li-bullet-0">
            <span className="c0">
              not, in connection with your use of the Services on behalf of a business (if any),
              commit any act or omission which in our sole discretion constitutes any of the
              following:
            </span>
          </li>
        </ol>
        <ol className="c1 lst-kix_list_4-1 start" start="1">
          <li className="c6 li-bullet-0">
            <span className="c0">
              offering incentives of any kind, such as discounts, freebies, refunds, gift cards,
              contest entries, offers, or deals in exchange for the posting of reviews of your
              business, or to prevent or remove reviews, and you understand and acknowledge that
              SQUADJJ may publicly notify consumers about such incentives and other attempts to
              obtain, prevent, or remove reviews;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              soliciting or asking for specific scores or review language from your customers, other
              than asking for reviews generally;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              writing reviews for your business or your business&rsquo;s competitors;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              attempting to generate automated, fraudulent, or otherwise invalid ad impressions,
              inquiries, conversions, ad clicks, or other actions;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              using any SQUADJJ trademark, logo or service mark in any manner without our prior
              written consent;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              misrepresenting your identity or affiliation to anyone in connection with SQUADJJ; or
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              authorizing or inducing any third party to do any of the foregoing on your behalf.
            </span>
          </li>
        </ol>
        <p className="c2">
          <span className="c0" />
        </p>
        <p className="c4">
          <span className="c0" />
        </p>
      </div>
    </article>
  );
}
