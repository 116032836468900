import Main from '../../layout/Main';
import GetTheAppContent from './GetTheAppContent';
import styles from './index.module.scss';

/** Content - Page SubView aggregating content
 * @param {?Boolean} basic - when `true` renders without the Footer component
 */
const Content = ({ basic }) => (
  <Main className={styles.GetTheApp} title="GetTheApp info" basic={basic} logo="dark">
    <GetTheAppContent />
  </Main>
);

/** GetTheApp - Page View - NAV NOT NEEDED (uses view below as `default`) */
export default function GetTheApp() {
  return <Content />;
}

/** GetTheAppBasic - Page View sans navigation links */
export function GetTheAppBasic() {
  return <Content basic />;
}
