import { Link } from 'react-router-dom';
import Main from '../../layout/Main';
import { ReactComponent as WomanShrugging } from '../../assets/woman-shrugging-dark-skin-tone.min.svg';
import errorStyles from '../../styles/Error.module.scss';

/** NotFound - Page View */
export default function NotFound() {
  return (
    <Main className={errorStyles.Error}>
      <article>
        <WomanShrugging className={errorStyles.PersonIllustration} />
        <p>
          Oops, how embarrassing! It looks like our internet is down. Check back in a minute to see
          if we’ve gotten our act together.
        </p>
        <Link to="/">Back to Home</Link>
      </article>
    </Main>
  );
}
