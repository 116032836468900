import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ContactEmail from '../../components/ContactEmail';
import scrollToTop from '../../utils/scrollToTop';
import getConfig from '../../config';
import './TermsOfUse.global.scss';

const { BASE_URI, LEGAL_ABBRV, LEGAL_NAME } = getConfig();

/** TermsOfUse - Page SubView for "Terms of Use" (exported to HTML via .docx)
 * 1. Remove leading <html>, <head>, </head>, <meta> and </html> tags
 * 2. Change <body class> to <div class title="">" (keep classes)
 * 3. Move <style> tag content into TermsOfUse.global.scss
 * 4. Replace-All "class=" --> "className="
 * 5. Replace heading <span> with <h1>
 */
export default function TermsOfUse({ id }) {
  const location = useLocation();
  const layout = location.pathname.charAt(1) === '_' ? '_' : '';
  const TERMSOFUSE_HASH_LINK = `${BASE_URI}/#/${layout}Terms#TermsOfUse`;
  const COMMUNITYSTANDARDS_HASH_LINK = `${BASE_URI}/#/${layout}Terms#CommunityStandards`;
  const SUBSCRIPTIONS_PG_LINK = `${BASE_URI}/#/${layout}Subscriptions`;

  return (
    <article className="TermsOfUse" id={id} title="Terms of Use legal">
      <div className="c23" title="">
        <h1>Terms of Use</h1>
        <p className="c0">
          <span className="c7 c15">Effective: October 26, 2023</span>
        </p>
        <p className="c0" id="h.gjdgxs">
          <span>
            Please read the following Terms of Use (the &ldquo;Terms of Use&rdquo;) carefully. These
            Terms of Use govern your use of the{' '}
          </span>
          <span className="c7">Squad</span>
          <span className="c2">
            {' '}
            mobile application (the &ldquo;App&rdquo;), any associated Content (as defined below),
            material, or functionality contained on the App, all interactive features, widgets,
            blogs, social networks and social network pages, and other online or wireless offerings
            that post a link to, or are otherwise covered by, these Terms of Use, whether accessed
            via computer, mobile device or other technology or any associated content, material, or
            functionality contained on the App (collectively, the &ldquo;Services&rdquo;). The App
            is the property of {LEGAL_NAME} (&ldquo;{LEGAL_ABBRV}
            &rdquo; or &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;). By accessing,
            browsing, downloading, using, or registering for, or otherwise using any Content,
            material, or functionality contained on the App, you acknowledge that you have read and
            understood these Terms of Use and that you accept and agree to be bound by them in full.
            If you do not agree to these Terms of Use, then do not use the App or any portion
            thereof. Any use of the App is at the sole risk of the user.
          </span>
        </p>
        <p className="c0">
          <span className="c7 c11">
            Please read these Terms of Use carefully. They require us to resolve disputes through
            arbitration on an individual basis rather than jury trials or class actions.
          </span>
        </p>
        <p className="c0">
          <span className="c4">The Services</span>
        </p>
        <p className="c0">
          <span>
            The Services constitute a technology platform accessible via the App that enables{' '}
          </span>
          <span className="c7">Squad</span>
          <span>
            {' '}
            members to participate in a community for those interested in Black-owned businesses,
            both owners and patrons.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Changes to the Terms of Use</span>
        </p>
        <p className="c0">
          <span className="c2">
            We may update these Terms of Use from time to time and may amend them at any time to
            incorporate additional terms specific to additional features, applications,
            opportunities, or Services that we may make available on or through the App. All such
            updates and amendments are effective immediately upon notice thereof, which we may give
            by any means, including, but not limited to, by posting a revised version of these Terms
            of Use.
          </span>
        </p>
        <p className="c0">
          <span className="c11 c7">
            You should review these Terms of Use often to stay informed of changes that may affect
            you, as your continued use of the App signifies your continuing consent to these Terms
            of Use.
          </span>
        </p>
        <p className="c0">
          <span>
            The most recent version of the Terms of Use is reflected by the effective date specified
            in these Terms of Use. The version of these Terms of Use posted at{' '}
          </span>
          <HashLink smooth to={`/${layout}Terms#TermsOfUse`}>
            {TERMSOFUSE_HASH_LINK}
          </HashLink>
          <span className="c2">
            {' '}
            on each respective date you use the App shall be the Terms of Use applicable to your
            access and use of the App on that date. Our electronically or otherwise properly stored
            copies of this Terms of Use shall be deemed to be the true, complete, valid, authentic,
            and enforceable copy of the version of these Terms of Use which were in force on each
            respective date you used the App.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Access and Use</span>
        </p>
        <p className="c0">
          <span className="c2">
            In these Terms of Use, &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to (a) you, the
            individual using the App, and (b) the business entity on whose behalf an individual or
            electronic agent is using the App. You represent and agree that all information that you
            provide to us in connection with your use of the App is and shall be true, accurate, and
            complete, to the best of your knowledge, ability, and belief. We reserve the right to
            terminate these Terms of Use, or to refuse, restrict, or discontinue access to the App
            (or any portions, components, or features thereof) to you or any other person or entity,
            for any reason or for no reason whatsoever, at any time, without notice or liability.
            Failure to comply with these Terms of Use may, among other things, result in the
            immediate termination of your access to and use of the App and any related Services.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} grants you a personal, non-exclusive, non-transferable, limited license to
            use the App and to view or access the App solely for your personal or internal use and
            subject to the condition that you do not (and do not allow any third party to) copy,
            modify, create a derivative work from, reverse engineer (except solely to the extent
            such prohibition is precluded by applicable law), reverse assemble, or otherwise attempt
            to discover any source code, sell, assign, sublicense, grant a security interest in, or
            otherwise transfer any right in or access to the App, the Services, or any other Content
            available via the App. You further agree neither to modify the App, or any part thereof,
            in any form or manner, nor to use any modified versions of the App or the Services, for
            any reason whatsoever, without the express written consent of {LEGAL_ABBRV}. You may not
            attempt to gain any unauthorized access to the App, its associated Content or computer
            systems, software, or networks. No redistribution of any Content, materials, or
            information contained on or offered through the Applications or the Services is
            permitted, unless expressly specified in a prior agreement between you and {LEGAL_ABBRV}
            .
          </span>
        </p>
        <p className="c0">
          <span className="c4">User Responsibility; No Unlawful or Prohibited Use</span>
        </p>
        <p className="c0">
          <span className="c2">
            Any use or attempted use of the App for the following is prohibited: (i) for any
            unauthorized, fraudulent or malicious purpose; (ii) that could damage, disable,
            overburden, or impair any server, or the network(s) connected to any server; (iii) that
            could interfere with any other person&rsquo;s or entity&rsquo;s use and enjoyment of the
            App; (iv) to gain unauthorized access to computer systems, software or networks
            connected to any server or systems or to any other accounts, including, without
            limitation, through hacking, password mining or any other means; (v) to access systems,
            data or information not intended by {LEGAL_ABBRV} to be made accessible to a user; (vi)
            to attempt to obtain any Content, materials or information through any means not
            intentionally made available by us; or (vii) for any use other than the purpose for
            which it was intended.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            In connection with your use of the App, you shall at all times conduct yourself with due
            regard to the public conventions and morals, as determined by {LEGAL_ABBRV} in its sole
            discretion. Without limiting the generality of the foregoing, you shall not do or commit
            any act, omission, or other thing that, in {LEGAL_ABBRV}&rsquo;s sole discretion, will
            tend to degrade {LEGAL_ABBRV} in society or bring {LEGAL_ABBRV} into public hatred,
            public disrepute, contempt, scorn, or ridicule, or that will tend to shock, insult or
            offend the community or public morals or decency or prejudice {LEGAL_ABBRV} or its
            public image in general.
          </span>
        </p>
        <p className="c0">
          <span>
            In addition, in connection with your use of the App, you agree that you will at all
            times comply with our Community Standards, located here:{' '}
          </span>
          <HashLink smooth to={`/${layout}Terms#CommunityStandards`}>
            {COMMUNITYSTANDARDS_HASH_LINK}
          </HashLink>
          <span>.</span>
          <span className="c2">
            {' '}
            You further agree that you will not (and will not attempt to):
          </span>
        </p>
        <ol className="c19 lst-kix_list_4-0 start" start="1">
          <li className="c17 c27 li-bullet-0">
            <span className="c2">
              Upload or transmit any message, information, data, text, software or images, or other
              content that is unlawful, immoral, harmful, threatening, abusive, harassing, tortious,
              defamatory, vulgar, obscene, libelous, or otherwise objectionable, or that may invade
              another&#39;s right of privacy or publicity;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Create a false identity for the purpose of misleading others or impersonate any person
              or entity, including, without limitation, any {LEGAL_ABBRV} representative, or falsely
              state or otherwise misrepresent your affiliation with a person or entity;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Upload or transmit any material that you do not have a right to reproduce, display or
              transmit under any law or under contractual or fiduciary relationships (such as
              nondisclosure agreements);
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Upload files that contain viruses, Trojan horses, worms, time bombs, cancel-bots,
              corrupted files, or any other similar software or programs that may damage the
              operation of another&#39;s computer or property of another;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Delete any author attributions, legal notices or proprietary designations or labels
              that you upload to any communication feature;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Upload or transmit any unsolicited advertising, promotional materials, &quot;junk
              mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid schemes&quot;
              or any other form of solicitation, commercial or otherwise;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Violate any applicable local, state, national, foreign or international law;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Upload or transmit any material that infringes any patent, trademark, service mark,
              trade secret, copyright or other proprietary rights of any party;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Delete or revise any material posted by any other person or entity;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Probe, scan, test the vulnerability of or breach the authentication measures of, the
              App or any related networks or systems;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Register, subscribe, attempt to register, attempt to subscribe, unsubscribe, or
              attempt to unsubscribe, any party for any products or Services if you are not
              expressly authorized by such party to do so;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              Harvest or otherwise collect information about others, including without limitation
              e-mail addresses, mobile numbers etc.; or
            </span>
          </li>
          <li className="c24 c17 li-bullet-0">
            <span className="c2">
              Use any robot, spider, scraper, or other automated or manual means to access the App
              or copy any Content or information on the App.
            </span>
          </li>
        </ol>
        <p className="c0">
          <span className="c2">
            Further, if you are accessing the App on behalf of a business, then you agree to the
            following additional terms:
          </span>
        </p>
        <ol className="c19 lst-kix_list_4-0" start="14">
          <li className="c27 c17 li-bullet-0">
            <span className="c2">
              you represent and warrant that you have the authority to act on behalf of the business
              or businesses associated with or claimed through your account and to bind any such
              business (including without limitation any corresponding business entity) to these
              Terms of Use;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              you will not misleadingly or inappropriately use consumer functions of the App for
              business activities, including without limitation, reporting reviews or direct
              messaging people who have reviewed your business;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              you represent and warrant that your business complies with applicable laws and does
              not offer, advertise, sell, or lease illegal products and/or services;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              you grant {LEGAL_ABBRV} and our affiliates a non-transferable, non-exclusive,
              royalty-free limited license to display your public website on the App, or allow for
              its display through iframes or other framing technology;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              you agree that we may contact you, including without limitation by phone or email,
              using the contact information you provide us, make publicly available, or that we have
              on record for your business, and that our communications (including without limitation
              phone calls) with you may be monitored and recorded for quality purposes;
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              you understand and acknowledge that non-disparagement clauses in certain consumer
              contracts, such as clauses that seek to restrict or prohibit reviews (including
              without limitation provisions that penalize consumers for posting reviews) about your
              business, are prohibited under certain laws, and you agree that you will not include
              such clauses in your consumer contracts, or otherwise attempt to enforce
              non-disparagement or &lsquo;gag&rsquo; clauses against consumers under any
              circumstances. You understand that we may publicly notify consumers if we have a good
              faith belief that such clauses are used by your business.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">
              you understand and acknowledge that {LEGAL_ABBRV} allows consumers to post information
              about your business, including without limitation photos, ratings, and reviews.
            </span>
          </li>
          <li className="c17 c24 li-bullet-0">
            <span className="c2">
              You represent and warrant that you will not, and will not authorize or induce any
              other party, to:
            </span>
          </li>
        </ol>
        <ol className="c19 lst-kix_list_6-2 start" start="1">
          <li className="c10 li-bullet-0">
            <span className="c2">
              offer incentives of any kind, such as discounts, freebies, refunds, gift cards,
              contest entries, offers, or deals in exchange for the posting of reviews of your
              business, or to prevent or remove reviews, and you understand and acknowledge that
              {LEGAL_ABBRV} may publicly notify consumers about such incentives and other attempts
              to obtain, prevent, or remove reviews;
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c2">
              solicit or ask for specific scores or review language from your customers, other than
              asking for reviews generally;
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c2">
              write reviews for your business or your business&rsquo;s competitors;
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c2">
              attempt to generate automated, fraudulent, or otherwise invalid ad impressions,
              inquiries, conversions, ad clicks, or other actions;
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c2">
              use any {LEGAL_ABBRV} trademark, tagline, website domain, social media handle or
              service mark in any manner without our prior written consent; or
            </span>
          </li>
          <li className="c17 c21 li-bullet-0">
            <span className="c2">
              misrepresent your identity or affiliation to anyone in connection with {LEGAL_ABBRV}.
            </span>
          </li>
        </ol>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} reserves the right to monitor the use of the App and the Services and to
            take whatever lawful actions it may deem appropriate in response to actual or suspected
            violations of the foregoing, including, without limitation, the suspension or
            termination of a user&rsquo;s access and/or account, whether temporarily or permanently.
            {LEGAL_ABBRV} may cooperate with legal authorities and/or third parties in the
            investigation of any suspected or alleged crime or civil wrong. Except as may be
            expressly limited by the Privacy Policy, {LEGAL_ABBRV} reserves the right at all times
            to disclose any information as {LEGAL_ABBRV} deems necessary to satisfy any applicable
            law, regulation, legal process or governmental request, or to edit, refuse to post or to
            remove any information or materials, in whole or in part, in {LEGAL_ABBRV}&rsquo;s sole
            discretion. You also agree to reimburse {LEGAL_ABBRV} for any damage, loss, cost or
            expense {LEGAL_ABBRV} incurs (including, without limitation, fees or costs of attorneys,
            accountants, professional advisors, and other experts incurred in connection with the
            defense or settlement of the foregoing) because of your use of the App for any unlawful
            or prohibited purpose. {LEGAL_ABBRV} reserves the right to remove any user-submitted
            material that may be infringing on another person&rsquo;s intellectual property rights
            with or without notice to the infringer and in accordance with applicable intellectual
            property or other laws, including, without limitation, the Digital Millennium Copyright
            Act (&ldquo;DMCA&rdquo;).
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Any information, images, communications, or material of any type or nature that you
            submit to the App, or any of our pages contained on a social media platform or website
            by e-mail, chats, posting, messaging, uploading, downloading, or otherwise
            (collectively, a &ldquo;Submission&rdquo;) is done at your own risk and without any
            expectation of privacy.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Proprietary Content</span>
        </p>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} respects the intellectual property rights of others and expects you to do
            the same. We have expended substantial time, effort and funds to create the App and to
            collect and provide the features, materials, opportunities, and Services that are
            available on or through the App. You understand and agree that {LEGAL_ABBRV} owns, or
            (where required, appropriate, or applicable) has been licensed or otherwise made
            available to us by third parties to use, all right, title, and interest in and to the
            App and the features, materials, opportunities, and Services made available on or
            through the App, and all information, text, data, computer code, music, artwork,
            databases, graphics, images, sound recordings, audio and visual clips, logos, software,
            and other materials contained therein, and the compilation, collection, design,
            selection, and arrangement thereof (collectively, the &ldquo;Content&rdquo;), including,
            without limitation, the design, structure, selection, coordination, expression,
            &ldquo;look and feel,&rdquo; and arrangement of such Content. You acknowledge that the
            Content constitutes valuable proprietary information that is protected by applicable
            intellectual property and other proprietary rights, laws, and treaties of the United
            States and other countries, and that you acquire no ownership interest by accessing and
            using the App and the Content. Such intellectual property and proprietary rights may
            include, but are not limited to, various patents, copyrights, trademarks and service
            marks, registered trademarks and service marks, trade dress protection, and trade
            secrets, and all such rights are and shall remain the property of {LEGAL_ABBRV} or its
            licensors and Content-providers. For the avoidance of doubt, &ldquo;Content&rdquo; does
            not include any third party materials or any features, opportunities, or services made
            available through any linked materials.
          </span>
        </p>


        <p className="c0">
          <span className="c7">SQUAD REWARDS</span>
        </p>
        <p>
          <span>
          Squad Rewards is one way in which participating businesses endeavor to reward and thank loyal customers for patronizing their businesses. Squad rewards are delivered via the Squad Elevate Your Hustle app. Each participating business can determine how they wish to distribute Squad rewards to their customers. In many cases rewards will be offered as a percentage of how much you spend with a participating business. In some cases, participating businesses may also offer Squad Rewards as a gift without accepting any payment. Each Squad Reward is worth $0.01. For clarity, 100 Squad Rewards is worth $1. Squad Rewards can only be redeemed at the participating business from where they were issued. Your Squad wallet displays all Squad Rewards that you have earned from all participating businesses.
          <p></p>
          <p className="c0">
          <span className="c7">OTHER IMPORTANT INFORMATION YOU SHOULD KNOW</span>
          </p>
          <p></p>
          There are no participation or membership fees associated with Squad Rewards. Squad Rewards are promotional, have no cash value and cannot be redeemed for cash. They can only be redeemed as payment for goods or services from participating businesses.
          <p></p>
          Your Squad Rewards are personal to you and may not be sold, transferred or assigned to, or shared with, family, friends or others, or used by you for any commercial purpose.
          <p></p>
          Without notice to you, Squad reserves the right to suspend and/or terminate your account and/or your participation in Squad Rewards if Squad determines, in its sole discretion, that you have violated these Terms of Use or that the use of your Squad Rewards account is unauthorized, deceptive, fraudulent or otherwise unlawful. Squad may, in its sole discretion, suspend, cancel or combine Squad Rewards accounts that appear to be duplicative. In the event that your participation in Squad Rewards is terminated, all accrued Squad Rewards in your account are void.
          <p></p>
          Without notice to you, Squad also reserves the right to "unregister" and make ineligible for Squad Rewards any Squad Card or account that has been inactive for two (2) consecutive years. Inactivity is defined as no Squad Rewards earned during such two (2) consecutive year period. In the event that your Squad Card or account is unregistered or rendered inactive, all accrued Squad Rewards in your account are void.
          <p></p>
          Squad reserves the right to change, modify, discontinue or cancel Squad Rewards or any part of the Squad Rewards program, at any time and in its sole discretion, without notice to you.
          <p></p>
          Squad Rewards is intended for personal use only. Commercial use is prohibited. Squad Rewards is not targeted towards, nor intended for use by, anyone under the age of 16.
          </span>
        </p>

        <p className="c0">
          <span className="c7">SQUAD CARD</span>
        </p>
        <p>
          <span>
          When using the Squad application, you have access to a wallet that holds the Squad Card. This is a virtual card that allows you to load a dollar value onto your Squad Card for use at participating businesses that accept Squad® cards. The dollar value that you load onto your Squad Card is a prepayment for the goods and services of participating stores. We offer the Squad Card to make it easier for you to shop and to make your relationship with participating stores more rewarding. Unless otherwise required by law or permitted by this Agreement, the dollar value on your Squad Card is nonrefundable and may not be redeemed for cash. The value on your Squad Card is not insured by the Federal Deposit Insurance Corporation (FDIC), nor does it earn interest..
          <p className="c0">
          <span className="c7">Loading Value on Your Card</span>
          </p>
          In the United States and Canada, you can load value on your Squad Card by using a credit card or Paypal account. There may be a delay from the time you pay the amount to be loaded onto your Squad Card and those funds being available for use
          <p></p>
          There is a minimum amount that you may load on any Squad Card at any given time, and that amount is typically $5. We may change any of these amounts at any time without notice to you.
          <p></p>
          If you use a credit or debit card to purchase or reload your Squad Card, prior to charging your credit or debit card, an authorization process will occur for the amount of your purchase or reload transaction ("Transaction"). The authorization process will validate the credit or debit card number, status, available credit or funds and billing information to ensure that it matches what the bank or card company has on file. Your bank or Squad may attempt to contact you for additional information prior to authorizing the Transaction amount.
          <p></p>
          For credit cards, once an authorization is received, you may notice a decrease in your available credit line. Your bank may hold this dollar amount from your credit line or available balance for a short period of time determined by the policy of your bank before your Transaction is fulfilled. For debit cards, your bank may pre-authorize a charge to your deposit or checking account and place a temporary hold on the funds before your Transaction is fulfilled. Regardless of whether you use a credit or debit card, your card will be charged only upon fulfillment of your Transaction. If your Transaction is cancelled, Squad will request an authorization reversal on your behalf. Reversal times may vary. We recommend contacting your bank or credit card company to learn about their authorization and authorization reversal policies.
          <p className="c0">
          <span className="c7">Transaction History</span>
          </p>
          We do not charge any activation, service, dormancy or inactivity fees in connection with your Squad Card. Your Squad Card has no expiration date nor does the value on your Squad Card ever expire.
          <p className="c0">
          <span className="c7">Fees and Expiration of Card Balances</span>
          </p>
          When you make a purchase with your Squad Card, the funds are moved from your Squad card to the business. Please check your online Transaction history regularly to ensure that your Transaction history and account balance are correct. You can check the balance of your Squad Card by tapping your wallet from the Squad Elevate Your Hustle app. We will not send you statements of activity on your Squad Card.
          <p className="c0">
          <span className="c7">Billing Errors, Corrections</span>
          </p>
          We will correct the balance of your Squad Card if we believe that a clerical, billing, or accounting error occurred. If you have questions regarding your Transaction history or any correction, or if you wish to dispute any Transaction or correction that has been applied to your Squad Card, please contact our Customer Service department below. Assuming you provide sufficient details, we will review your claim and tell you what we find. We will correct any error promptly after we finish our review. If we do not find any error, we will explain what we found. We have no obligation to review or correct any billing error unless you provide us sufficient notice for us to review your claim within sixty (60) days of the date of the Transaction in question.
          <p className="c0">
          <span className="c7">Fraud Associated with Your Squad Card or Card Balance</span>
          </p>
          <p className="c0">
          WWe will not accept any Squad Card, or will limit use of any Squad Card or Squad Card balance, if we reasonably believe that the use is unauthorized, fraudulent or otherwise unlawful. If you believe your Squad Card has been disabled in error, please contact us.
          </p>
          You should treat your Squad Card like cash and not disclose or share your Squad application username and password with anyone. If your phone is lost or stolen, anyone who obtains possession of your phone may have access to your Squad app and may use it to pay for transactions. You are responsible for all transactions on your Squad Card, including unauthorized transactions.
          </span>
        </p>



        <p className="c0">
          <span className="c7">Subscriptions</span>
        </p>
        <p className="c0">
          <span>
            From time to time, {LEGAL_ABBRV} may offer you opportunities to purchase
            subscription-based Services through the App. To purchase a subscription, you must be at
            least 18 years or older or at least the age of majority in the jurisdiction where you
            reside or from which you use the App. A valid credit card or other supported payment
            method is required to purchase a subscription. By subscribing, you agree that your
            payment method will be billed monthly, beginning on the date that you first register for
            subscription Services unless another date is specified in the subscription Services
            offer, in the amount specified in the subscription Services offer. For avoidance of
            doubt, if you receive free access or a trial or evaluation period in connection with a
            subscription Services offer, your use of the subscription Services remains otherwise
            subject to this paragraph during the trial or evaluation period. Subscription products
            are nonrefundable but may be cancelled at any time by navigating to{' '}
            <Link to={`/${layout}Subscriptions`} onClick={() => scrollToTop()}>
              {SUBSCRIPTIONS_PG_LINK}
            </Link>
            . Subject to your payment of the subscription fees, your compliance with these Terms of
            Use, and availability of the subscription Services, your subscription will enable you to
            access the subscription features specified in the subscription Services offer.{' '}
            {LEGAL_ABBRV} reserves the right to discontinue any subscription Services at any time
            for any reason, either on a subscriber-by-subscriber basis or for all subscribers.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Disclaimer of Warranties</span>
        </p>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} does not promise, covenant, represent, warrant, or guarantee that you or
            any other user of the App will obtain any particular or tangible result or goal through
            the use of the App, or obtain any other product or service in connection with use of the
            App. It is solely your responsibility to take appropriate precautions to ensure that any
            information, materials, software, or data that you submit to or through the App or
            {LEGAL_ABBRV}, or that you access, use, download, or otherwise obtain on or through the
            App or
            {LEGAL_ABBRV}, are: (a) up-to-date, accurate, complete, reliable, truthful, and suitable
            to and appropriate for the purpose for which they are intended; and (b) free of viruses
            and other disabling devices and destructive routines.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            THE INFORMATION, SOFTWARE, CONTENT, SERVICES, AND MATERIALS AVAILABLE FROM OR PROVIDED
            ON THE SYSTEM OR VIA THE SERVICES ARE PROVIDED BY {LEGAL_ABBRV} AND/OR OUR THIRD PARTY
            SUPPLIERS OR LICENSORS ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS,
            WITHOUT WARRANTIES OR GUARANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING,
            BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} AND/OR OUR AFFILIATED COMPANIES, OFFICERS, MEMBERS, THIRD PARTY SUPPLIERS
            AND/OR LICENSORS DO NOT REPRESENT, WARRANT OR COVENANT THAT THE SYSTEM, CONTENT OR ANY
            MATERIALS, PRODUCTS, OR SERVICES AVAILABLE ON OR THROUGH THE SYSTEM OR THROUGH{' '}
            {LEGAL_ABBRV}
            ARE OR WILL BE ACCURATE, CURRENT, COMPLETE, FREE OF TECHNICAL AND TYPOGRAPHICAL ERRORS,
            RELIABLE, OR APPROPRIATE FOR ANY PARTICULAR USE TO WHICH YOU OR ANY THIRD PARTY MAY
            CHOOSE TO PUT THEM, THAT THEY ARE OR WILL BE AVAILABLE ON AN UNINTERRUPTED AND
            ERROR-FREE BASIS, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SYSTEM AND THE CONTENT,
            MATERIALS, PRODUCTS, AND SERVICES AVAILABLE ON OR THROUGH THE SYSTEM AND THROUGH{' '}
            {LEGAL_ABBRV}
            ARE FREE OF VIRUSES OR OTHER DISABLING DEVICES OR HARMFUL COMPONENTS. {LEGAL_ABBRV}
            PERIODICALLY AMENDS, CHANGES, ADDS, DELETES, UPDATES, OR ALTERS THE SYSTEM AND THE
            SERVICES WITHOUT NOTICE. FURTHER, {LEGAL_ABBRV} ASSUMES NO LIABILITY OR RESPONSIBILITY
            FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THE SYSTEM OR THE SERVICES. {LEGAL_ABBRV}{' '}
            SPECIFICALLY DISCLAIMS ANY DUTY TO UPDATE THE CONTENT, OR ANY OTHER INFORMATION ON THE
            SYSTEM. WE DO NOT MAKE ANY REPRESENTATION OR WARRANTY WHATSOEVER REGARDING THE EXISTENCE
            OF ANY MARKET DEMAND FOR BUSINESSES LISTED ON OUR APP OR ANY EXPECTATION OF PROFITS
            ARISING OUT OF LISTING ON OUR APP OR PARTICIPATION IN THE {LEGAL_ABBRV} COMMUNITY.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            IN USING THE APP, YOU ACCEPT THE INHERENT SECURITY IMPLICATIONS OF DATA TRANSMISSION
            OVER THE INTERNET AND THE WORLD WIDE WEB CANNOT ALWAYS BE GUARANTEED AS COMPLETELY
            SECURE. THEREFORE, YOUR USE OF THE APP WILL BE AT YOUR OWN RISK.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Limitation of Liability</span>
        </p>
        <p className="c0">
          <span className="c2">
            IN NO EVENT SHALL {LEGAL_ABBRV} OR ITS CONTRACTORS, SUPPLIERS, CONTENT-PROVIDERS, AND
            OTHER SIMILAR ENTITIES, OR THE OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AND
            AGENTS OF EACH OF THE FOREGOING, BE LIABLE TO YOU OR ANY OTHER THIRD PARTY FOR ANY
            DIRECT OR INDIRECT LOSS, COST, DAMAGE, OR OTHER INJURY, WHETHER IN CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH: (A)
            THE USE OF, OR RELIANCE ON, THE SYSTEM, THE CONTENT, MATERIALS, AND SERVICES ACCESSIBLE
            ON OR THROUGH THE SYSTEM OR THROUGH {LEGAL_ABBRV}; (B) THE USE, COPYING, OR DISPLAY OF
            THE SYSTEM OR THE CONTENT OR THE TRANSMISSION OF INFORMATION TO OR FROM THE SYSTEM OVER
            THE INTERNET; (C)
            {LEGAL_ABBRV}&rsquo;S PERFORMANCE OF, OR FAILURE TO PERFORM, ITS OBLIGATIONS IN
            CONNECTION WITH THESE TERMS OF USE; (D) ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT BY
            YOU, OTHER USERS OF THE SYSTEM, OR OTHER THIRD PARTIES; (E) YOUR PURCHASE AND USE OF ANY
            GOODS OR SERVICES PROVIDED BY {LEGAL_ABBRV} OR ANY THIRD PARTY; OR (F) THE AVAILABILITY,
            RELIABILITY, ACCURACY, TIMELINESS, OR QUALITY OF ANY SERVICES OFFERED, MADE AVAILABLE,
            PROVIDED, OR ACCEPTED BY YOU OR ANY THIRD PARTY, IN CONNECTION WITH THE SYSTEM OR ITS
            USE.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            UNDER NO CIRCUMSTANCES SHALL {LEGAL_ABBRV}, OR ITS CONTRACTORS, SUPPLIERS,
            CONTENT-PROVIDERS, AND OTHER SIMILAR ENTITIES, OR THE OFFICERS, DIRECTORS, EMPLOYEES AND
            AGENTS OF EACH OF THE FOREGOING, BE LIABLE TO YOU OR ANY OTHER THIRD PARTY FOR ANY
            INDIRECT, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, SPECIAL, OR SIMILAR DAMAGES OR COSTS
            (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS OR DATA, LOSS OF GOODWILL, LOSS OF OR
            DAMAGE TO PROPERTY, LOSS OF USE, BUSINESS INTERRUPTION, AND CLAIMS OF THIRD PARTIES)
            ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE, THE SYSTEM, THE SERVICES, OR
            ANYTHING DESCRIBED IN THE FOREGOING CLAUSES (A) THROUGH (F) OF THIS PARAGRAPH, OR ANY
            OTHER CAUSE BEYOND THE CONTROL OF {LEGAL_ABBRV}, EVEN IF {LEGAL_ABBRV} WAS ADVISED,
            KNEW, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES OR COSTS.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            IN A JURISDICTION THAT DOES NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
            CERTAIN DAMAGES, THE AGGREGATE LIABILITY OF {LEGAL_ABBRV} AND ITS CONTRACTORS,
            SUPPLIERS, CONTENT-PROVIDERS, AND OTHER SIMILAR ENTITIES, AND THE OFFICERS, DIRECTORS,
            EMPLOYEES, REPRESENTATIVES, AND AGENTS OF EACH OF THE FOREGOING, SHALL BE LIMITED IN
            ACCORDANCE WITH THESE TERMS OF USE TO THE GREATER OF (I) FIFTY US DOLLARS ($50 USD); OR
            (II) THE MOST LIMITED AMOUNT PERMITTED BY LAW.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} reserves the right to do any of the following at any time without notice:
            (i) modify, suspend, or terminate operation of or access to the App, or any portion of
            the App, for any reason; (ii) modify or change the App, or any portion of the App, and
            any applicable policies or terms; and (iii) interrupt the operation of the App, or any
            portion of the App, as necessary to perform routine or non-routine maintenance, perform
            error correction, or make other changes.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Indemnification</span>
        </p>
        <p className="c0">
          <span className="c2">
            YOU AGREE TO INDEMNIFY, DEFEND AND HOLD {LEGAL_ABBRV} HARMLESS FROM AND AGAINST ALL
            CLAIMS, DEMANDS, SUITS OR OTHER PROCEEDINGS, AND ALL RESULTING LOSS, DAMAGE, LIABILITY,
            COST, AND EXPENSE (INCLUDING, WITHOUT LIMITATION, ATTORNEYS&rsquo; FEES), BROUGHT BY ANY
            THIRD PARTY IN CONNECTION WITH OR ARISING OUT OF CONTENT, DATA, OR INFORMATION THAT YOU
            SUBMIT, POST TO, OR TRANSMIT THROUGH THE APP OR {LEGAL_ABBRV}, YOUR ACCESS TO AND USE OF
            THE CONTENT, THE APP, AND OTHER MATERIALS, PRODUCTS, AND SERVICES AVAILABLE ON OR
            THROUGH THE APP AND {LEGAL_ABBRV}, YOUR VIOLATION OF THESE TERMS OF USE, OR YOUR
            VIOLATION OF ANY RIGHTS OF ANOTHER. WE RESERVE, AND YOU GRANT TO US, THE RIGHT TO ASSUME
            EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION BY YOU HEREUNDER.
            ALL RIGHTS AND DUTIES OF INDEMNIFICATION THAT ARE SET FORTH HEREIN SHALL SURVIVE
            TERMINATION OF THESE TERMS OF USE.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Links to Other Parties&rsquo; Content</span>
        </p>
        <p className="c0">
          <span className="c2">
            For your convenience, certain hyperlinks or features may be provided on the App that
            link to other applications or websites which are not under the control of {LEGAL_ABBRV}{' '}
            (the &ldquo;Linked Content&rdquo;). {LEGAL_ABBRV} does not endorse or sponsor any Linked
            Content and is not responsible for the availability, accuracy, substance, or any other
            aspect of the Linked Content. {LEGAL_ABBRV} disclaims all liability for such Linked
            Content, for all access to and use thereof, and for use of the links to such Linked
            Content. We also disclaim all liability, and make no representations or warranties, with
            respect to any products or services made available, sold, or provided to you by any
            third party. Your use of other applications or websites, and any purchases of products
            or services, volunteering of time or services, or making of charitable contributions
            through other websites, is subject to the terms and conditions of such other
            applications or websites. You agree that you will bring no suit or claim against{' '}
            {LEGAL_ABBRV}, and {LEGAL_ABBRV} has no obligation to participate in, arising from or
            based upon any such use of other applications, websites, or materials contained therein
            (including without limitation the Linked Content). {LEGAL_ABBRV} has no responsibility
            for information provided to third parties through the Applications, including without
            limitation through any Linked Content. Hyperlinks to such Linked Content on the App do
            not imply that: (a) {LEGAL_ABBRV} is affiliated or associated with any Linked Content;
            (b) {LEGAL_ABBRV} is legally authorized to use any trademark, trade name, logo, or
            copyright symbol displayed in connection with or accessible through such links; or (c)
            any Linked Content is authorized to use any trademark, trade name, logo, or copyright
            symbol of {LEGAL_ABBRV}.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Disclaimer of Third Party Information</span>
        </p>
        <p className="c0">
          <span className="c2">
            The App may contain offers for sale of merchandise or services or promotional offers not
            provided by {LEGAL_ABBRV}. Differences in the terms of an offer between the App and a
            merchant&rsquo;s website will be governed by the terms shown on the merchant&rsquo;s
            website. {LEGAL_ABBRV} is not responsible or liable for any such differences or
            discrepancies or the performance of any products or services obtained via such
            third-party website or for not-honoring of any offer by any merchant.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            You understand and agree that {LEGAL_ABBRV} shall in no event be responsible or held
            liable in connection with any dispute, harm, or loss between, among, or by any user or
            combination of users of the App. Without limiting the generality of the foregoing, in
            the event of a dispute between you and any such third party who provides certain offers
            for sale of merchandise or services, {LEGAL_ABBRV} shall in no event be responsible or
            held liable in any manner whatsoever. Further, if you are accessing the site on behalf
            of a business, you agree that that {LEGAL_ABBRV} will not be responsible or held liable
            to you or your business in any manner whatsoever, including without limitation in
            connection with any disputes between you and any other business or consumer user of the
            App.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Accounts, Passwords, and Security</span>
        </p>
        <p className="c0">
          <span className="c2">
            Certain features or services offered on or through the App may require you to open an
            account (including without limitation establishing a login ID and password). You are
            entirely and exclusively responsible for maintaining the confidentiality of your account
            information, including without limitation your login ID and password, and for any and
            all activity that occurs under your account or login ID. You agree to notify{' '}
            {LEGAL_ABBRV}
            immediately upon learning of any unauthorized use of your account, login ID, or password
            or any other breach of security. However, you may be held liable for losses incurred by
            {LEGAL_ABBRV} or any other user of or visitor to the App due to another person using
            your account, login ID, or password.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            You may not use any other user&rsquo;s account, login ID, or password at any time
            without the express permission and consent of the holder of that account, login ID, or
            password. You specifically understand and agree that {LEGAL_ABBRV} cannot and will not
            be liable for any loss or damage arising from your failure to comply with these
            obligations.
          </span>
        </p>
        <p className="c0">
          <span className="c4">General</span>
        </p>
        <p className="c0">
          <span className="c2">
            These Terms of Use (as each may be revised and amended from time to time according to
            their respective terms) collectively constitute the entire agreement with respect to
            your access to and use of the App and the Content. You agree that you shall not contest
            the admissibility or enforceability of {LEGAL_ABBRV}&rsquo;s copy of these Terms of Use
            in connection with any action or proceeding arising out of or relating to these Terms of
            Use. Except as expressly provided for herein, these Terms of Use do not confer any
            rights, remedies, or benefits upon any person or entity other than you and {LEGAL_ABBRV}
            .{LEGAL_ABBRV} may assign its rights and duties under these Terms of Use at any time to
            any third party without notice. You may not assign these Terms of Use without the prior
            written consent of {LEGAL_ABBRV}. These Terms of Use shall be binding on and inure to
            the benefit of the parties hereto and their respective successors and assigns.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            To the extent any portion of these Terms of Use shall be determined to be unenforceable
            by a court of competent jurisdiction, such portion will be modified by the court solely
            to the extent necessary to cause such portion to be enforceable, and these Terms of Use
            as so modified will remain in full force and effect. Any waiver of any provisions
            contained in these Terms of Use by {LEGAL_ABBRV} shall not be deemed to be a waiver of
            any other right, term, or provision of these Terms of Use. Any rights not expressly
            granted herein are reserved.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            You agree that any violation, or threatened violation, by you of these Terms of Use,
            constitutes an unlawful and unfair business practice that will cause us irreparable and
            unquantifiable harm. You also agree that monetary damages would be inadequate for such
            harm and consent to our obtaining any injunctive or equitable relief that we deem
            necessary or appropriate. These remedies are in addition to any other remedies we may
            have at law or in equity.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Jurisdiction</span>
        </p>
        <p className="c0">
          <span className="c2">
            These Terms of Use shall be governed by, and construed and enforced in accordance with,
            the laws of the State of Georgia, as such laws are applied to agreements entered and to
            be performed entirely within such State. If any provision of these Terms of Use shall be
            unlawful, void or for any reason unenforceable, then that provision shall be deemed
            severed from these Terms of Use and shall not affect the validity and enforceability of
            any remaining provisions. Except to the extent that you and {LEGAL_ABBRV} have entered
            into a separate written agreement regarding the provision of Services, these Terms of
            Use are the entire agreement between the parties relating to the matters contained
            herein and shall not be modified except in writing signed by a duly authorized officer
            of {LEGAL_ABBRV}.
          </span>
        </p>
        <p className="c17 c22">
          <span className="c4">Dispute Resolution by Binding Arbitration</span>
        </p>
        <p className="c0">
          <span className="c7 c18">Please read this carefully. It affects your rights</span>
          <span className="c7">.</span>
        </p>
        <p className="c0">
          <span className="c7 c18">
            We each agree to resolve all disputes through binding arbitration or small claims court
            instead of in courts of general jurisdiction.
          </span>
        </p>
        <p className="c0">
          <span>
            Arbitration is more informal than a lawsuit in court. Arbitration in the context of this
            matter uses a single neutral arbitrator instead of a judge or jury, allows for more
            limited discovery than in court, and is subject to very limited review by courts.{' '}
          </span>
          <span className="c7 c18">
            Any arbitration under this Agreement will take place on an individual basis; class
            arbitrations and class actions are not permitted.
          </span>
        </p>
        <p className="c0 c14">
          <span className="c13 c7" />
        </p>
        <p className="c0 c14">
          <span className="c7 c13" />
        </p>
        <p className="c0">
          <span className="c13 c7">Claims Subject to Arbitration:</span>
        </p>
        <p className="c0">
          <span className="c2">
            {LEGAL_ABBRV} and you agree to arbitrate all disputes and claims between us, except for
            claims arising from bodily injury or death. This arbitration provision is intended to be
            broadly interpreted. It includes, but is not limited to:
          </span>
        </p>
        <ul className="c19 lst-kix_list_7-0 start">
          <li className="c9 li-bullet-0">
            <span className="c2">
              claims arising out of or relating to any aspect of the relationship between you and
              us, whether based in contract, tort, fraud, misrepresentation, consumer protection or
              any other statutory or common-law legal theory;
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              claims that arose before the existence of this or any prior agreement (including, but
              not limited to, claims relating to advertising);
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              claims for mental or emotional distress or injury not arising out of bodily injury;
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              claims that are currently the subject of purported class action litigation in which
              you are not a member of a certified class; and
            </span>
          </li>
          <li className="c25 c16 li-bullet-0">
            <span className="c2">
              claims that may arise after the termination of this Agreement.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span>
            References to &ldquo;{LEGAL_ABBRV},&rdquo; &ldquo;you,&rdquo; and &ldquo;us&rdquo;
            include our respective predecessors in interest, successors, and assigns, as well as our
            respective past, present, and future subsidiaries, affiliates, related entities, agents,
            employees, and all authorized or unauthorized users or beneficiaries of {LEGAL_ABBRV}{' '}
            Services or products under past, present, or future agreements between us. This
            arbitration provision does not preclude you from bringing issues to the attention of
            federal, state, or local agencies. Such agencies can, if the law allows, seek relief
            against us on your behalf.{' '}
          </span>
          <span className="c7 c18">
            You agree that, by entering into and accepting these Terms of Use, you and {LEGAL_ABBRV}{' '}
            are each waiving the right to a trial by jury or to participate in a class action.{' '}
          </span>
          <span className="c2">
            These Terms of Use evidence a transaction in interstate commerce, and thus the Federal
            Arbitration Act governs the interpretation and enforcement of this provision. This
            arbitration provision shall survive termination of these Terms of Use.
          </span>
        </p>
        <p className="c0">
          <span className="c13 c7">Pre-Arbitration Notice of Dispute:</span>
        </p>
        <p className="c0">
          <span className="c2">
            A party who intends to seek arbitration must first send to the other party a written
            notice of dispute (&ldquo;Notice&rdquo;). The Notice to {LEGAL_ABBRV} may be sent by
            U.S. mail or professional courier service to: William R. Poplin, Jr., Kilpatrick,
            Townsend &amp; Stockton, 1100 Peachtree Street, N.E., Suite 2800, Atlanta, GA 30309-4530
            (the &quot;Notice Address&quot;). The Notice must include at least all of the following:
            (a) your name; (b) your account name or number; (c) the Services (if any) to which your
            claim pertains; (d) a description of the nature and basis of the claim or dispute; (e)
            an explanation of the specific relief sought and the basis for the calculations; (f)
            your signature; and (g) if you have retained an attorney, your signed statement
            authorizing {LEGAL_ABBRV} to disclose your confidential account-related records to your
            attorney if necessary in resolving your claim.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            If {LEGAL_ABBRV} and you do not reach an agreement to resolve the claim within sixty
            (60) days after the Notice is received, you or {LEGAL_ABBRV} may commence an arbitration
            proceeding. (If either you or we send the other an incomplete Notice, the 60-day period
            begins only after a complete Notice is received.) Information on how to commence an
            arbitration proceeding, including how to file a consumer arbitration online, is
            currently available at adr.org/support. A copy of the arbitration demand must be sent to
            the Notice Address listed above.
          </span>
        </p>
        <p className="c0">
          <span className="c13 c7">Arbitration Procedure:</span>
        </p>
        <p className="c0">
          <span className="c2">
            The arbitration will be governed by the then-current Consumer Arbitration Rules
            (&ldquo;AAA Rules&rdquo;) of the American Arbitration Association (&ldquo;AAA&rdquo;),
            as modified by this arbitration provision, and will be administered by the AAA. (If the
            AAA is unavailable, another arbitration provider shall be selected by the parties or, if
            the parties cannot agree on a provider, by the Superior Court of Fulton County,
            Georgia.) The AAA Rules are available online at adr.org or may be requested by writing
            to the Notice Address. All issues are for the sole arbitrator to decide. The arbitrator
            may consider rulings in other arbitrations involving different customers, but an
            arbitrator&rsquo;s ruling will not be binding in proceedings involving different
            customers. Unless {LEGAL_ABBRV} and you agree otherwise, any arbitration hearings will
            take place in Atlanta, Fulton County, Georgia. If your claim is for $5,000 or less, we
            agree that you may choose whether the arbitration will be conducted solely on the basis
            of documents submitted to the arbitrator or through a telephonic, videoconference, or
            in-person hearing as established by the AAA Rules. If your claim exceeds $5,000, the
            right to a hearing will be determined by the AAA Rules. During the arbitration, the
            amount of any settlement offer made by {LEGAL_ABBRV} or you shall not be disclosed to
            the arbitrator until after the arbitrator determines the relief, if any, to which you or
            {LEGAL_ABBRV} is entitled. Regardless of the manner in which the arbitration is
            conducted, the arbitrator shall issue a reasoned written decision sufficient to explain
            the essential findings and conclusions on which his or her decision is based. Except as
            provided below, the arbitrator can award the same damages and relief that a court can
            award under applicable law.
          </span>
        </p>
        <p className="c0">
          <span className="c13 c7">Arbitration Fees:</span>
        </p>
        <p className="c0">
          <span className="c2">
            The payment of the AAA filing, administration, case-management, hearing, and arbitrator
            fees will be governed by the AAA rules. In addition, if the arbitrator finds that either
            the substance of your claim or the relief sought in the arbitration demand is frivolous
            or brought for an improper purpose (as measured by the standards set forth in Rule 11(b)
            of the Federal Rules of Civil Procedure), then the payment of all such fees will be
            governed by the AAA Rules. In such case, you agree to reimburse {LEGAL_ABBRV} for all
            monies previously disbursed by it that are otherwise your obligation to pay under the
            AAA Rules.
          </span>
        </p>
        <p className="c0">
          <span className="c13 c7">Requirement of Individual Arbitration:</span>
        </p>
        <p className="c0">
          <span className="c2">
            The arbitrator may award relief (including, but not limited to, damages, restitution,
            declaratory relief, and injunctive relief) only in favor of the individual party seeking
            relief and only to the extent necessary to provide relief warranted by that
            party&rsquo;s individual claim. YOU AND {LEGAL_ABBRV} AGREE THAT EACH MAY BRING CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL
            PROCEEDING. Further, unless both you and {LEGAL_ABBRV} agree otherwise, the arbitrator
            may not consolidate more than one person&rsquo;s or entity&rsquo;s claims and may not
            otherwise preside over any form of a representative, class, or private attorney general
            proceeding. If, after exhaustion of all appeals, any of these prohibitions on
            non-individualized relief; class, representative, and private attorney general claims;
            and consolidation is found to be unenforceable with respect to a particular claim or
            with respect to a particular request for relief (such as a request for injunctive
            relief), then the parties agree that such a claim or request for relief shall be decided
            by a court after all other claims and requests for relief are arbitrated.
          </span>
        </p>
        <p className="c0">
          <span className="c4">Contact Us</span>
        </p>
        <p className="c0">
          <span>
            {LEGAL_ABBRV} welcomes any comments or questions you may have regarding these Terms of
            Use or the App. Please send any comments or questions to{' '}
          </span>
          <ContactEmail />
          <span>.</span>
        </p>
        <p className="c17 c28">
          <span className="c2">Please check back regularly for updates and changes.</span>
        </p>
        <div>
          <p className="c6 c14 c17">
            <span className="c2" />
          </p>
        </div>
      </div>
    </article>
  );
}
