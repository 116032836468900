import { Link, useLocation } from 'react-router-dom';
// import './SubscriptionsDetails.global.scss';

/** SubscriptionsDetails - Page SubView for "Subscriptions" (exported to HTML via .docx)
 * 1. Remove leading <html>, <head>, </head>, <meta> and </html> tags
 * 2. Change <body class> to <div class title="">" (keep classes)
 * 3. Move <style> tag content into SubscriptionsDetails.global.scss
 * 4. Replace-All "class=" --> "className="
 * 5. Replace heading <p>+<span> with <h1>
 */
export default function SubscriptionsDetails({ id = null }) {
  const location = useLocation();
  const layout = location.pathname.charAt(1) === '_' ? '_' : '';

  // Placeholder until we get official page-content from SQUADJJ legal

  return (
    <article className="SubscriptionsDetails" id={id} title="Subscriptions legal">
      <div title="">
        <p>
          Subscription services aren’t available at this time. We’ll publish updates here as they
          become available.
        </p>
        <Link to={`/${layout}Terms`}>Back to Terms of Use</Link>
      </div>
    </article>
  );
}
