import contactStyles from '../../styles/ContactType.module.scss';

const GENERAL_CONTACT_EMAIL = 'info@squadeyh.com';

/** ContactEmail - Component View for email link UX consistency and accessibility
 * @param {string} email - Full email address
 */
export default function ContactEmail({ email = GENERAL_CONTACT_EMAIL, subject }) {
  const linkable = `${email}${subject ? `?subject=${subject}` : ''}`;
  const title = `Email: ${email}${subject ? ` - Subject: ${subject}` : ''}`;

  return (
    <a href={`mailto:${linkable}`} title={title} aria-label="Email Address">
      <span className={contactStyles.ContactType} itemProp="email">
        {email}
      </span>
    </a>
  );
}
