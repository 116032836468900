import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { ReactComponent as LogoDark } from '../../assets/logo-squad-dark.min.svg';
import { ReactComponent as LogoLight } from '../../assets/logo-squad-light.min.svg';
// import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import classNames from '../../utils/classNames';
import scrollToTop from '../../utils/scrollToTop';
import styles from './index.module.scss';
import getConfig from '../../config';

const { BRANDING, TAGLINE } = getConfig();

smoothscroll.polyfill();

const AppLogo = ({ logo, onClick }) => (
  <div id="logo" className={styles.Logo} title={`${BRANDING} :: ${TAGLINE}`}>
    {logo === 'dark' ? <LogoDark onClick={onClick} /> : <LogoLight onClick={onClick} />}
  </div>
);

/** Main - Layout Component */
export default function Main({ className, title = '', logo = 'light', basic, children }) {
  const location = useLocation();

  /** Parallax container element height setting on Home page */
  useEffect(() => {
    document.body.classList[location.pathname === '/' && location.hash === '' ? 'add' : 'remove'](
      'parallax',
    );
  }, [location.hash, location.pathname]);

  return (
    <>
      {/* <Nav /> */}
      <AppLogo logo={logo} onClick={() => scrollToTop()} />
      <main className={classNames(styles.Main, className)} title={title}>
        <section title="">{children}</section>
      </main>
      {basic ? null : <Footer />}
    </>
  );
}

export { AppLogo };
