import Main from '../../layout/Main';
import RSVPForm from './RSVPForm';
// import useScrollToHash from '../../hooks/useScrollToHash';
import styles from './index.module.scss';

/** Content - Page SubView aggregating content
 * @param {?Boolean} basic - when `true` renders without the Footer component
 */
const Content = ({ basic, ...rest }) => (
  // useScrollToHash({ hashRouter: true }); // If there are page anchors (see Terms pg)
  <Main className={styles.RSVP} title="RSVP info" basic={basic} logo="dark">
    <RSVPForm {...rest} />
  </Main>
);

/** RSVP - Page View - NAV NOT NEEDED (uses view below as `default`) */
export default function RSVP({ ...rest }) {
  return <Content {...rest} />;
}

/** RSVPBasic - Page View sans navigation links */
export function RSVPBasic({ ...rest }) {
  return <Content {...rest} />;
}
