import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classNames from '../../utils/classNames';
import scrollToTop from '../../utils/scrollToTop';
import styles from './CommunityGuidelines.module.scss';
/** Note: Code using styles from SASS mixin `nonBreakingHyphen()` - see its note
 *                                    ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *        Uses non-breaking hyphen "‑" as necessary to keep hyphen with word
 * @see https://stackoverflow.com/a/8753317/2895948
 *
 * SCSS - for UX Consistency, we will use classNames via TermsOfUse.global.scss
 */

/** CommunityGuidelines - Page SubView */
export default function CommunityGuidelines({ id = null }) {
  return (
    <article
      className={classNames(styles.CommunityGuidelines, 'TermsOfUse')}
      id={id}
      title="Community Guidelines details"
    >
      <div className="c23" title="">
        <h1>Squad Community Guidelines</h1>
        <p className="c0">
          <span className="c2">
            People use Squad to connect with community, leverage resources, and find Black-owned and{' '}
            <span className={styles.nonBreakingHyphen}>‑</span>empowered businesses. Offering a safe
            and supportive environment where that happens is our top priority. We also recognize the
            importance of providing a space where people feel empowered to be themselves.
          </span>
        </p>
        <p className="c0">
          <span className="c7 c11">
            The Squad Community Guidelines outline behaviors that are inappropriate or prohibited in
            the app.
          </span>
        </p>

        <p className="c0">
          <span className="c4">Privacy In Public Spaces</span>
        </p>
        <p className="c0">
          <span className="c2">
            The Squad app is an online community and is open to the public—all content on the
            platform can be read or seen by anyone. We are committed to protecting your privacy and
            information. To help us help you, we ask that you do not share personal information such
            as bank or credit card numbers, passport info, PIN numbers, social security numbers and
            passwords. Doing so could result in personal, professional or financial injury. Squad is
            not responsible for consequences related to the sharing of private information.
          </span>
        </p>

        <p className="c0">
          <span className="c4">Comments &amp; Reviews</span>
        </p>
        <p className="c0">
          <span className="c2">
            Comments and reviews should be relative to the topics you are responding to. We reserve
            the right to delete inappropriate, misleading, fraudulent, libelous, spam (i.e., posting
            the same comment repeatedly), or unsolicited sales and marketing content. People who
            post such content can also be banned or suspended.
          </span>
        </p>

        <p className="c0">
          <span className="c4">Permissions</span>
        </p>
        <p className="c0">
          <span className="c2">
            Sharing is caring—but not without permission. Do not post content without proper
            permission from the owner(s). Copyrighted, trademarked and other intellectual property
            will be flagged for removal.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Information shared publicly or privately on Squad can be used or reproduced without
            prior permission or consent.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Unsolicited ideas or suggestions automatically become property of Squad, without
            limitations or obligation to compensate.
          </span>
        </p>

        <p className="c0">
          <span className="c4">Kindness &amp; Respect</span>
        </p>
        <p className="c0">
          <span className="c2">
            We believe that everyone can make a positive contribution to the Squad community and we
            want to hear your voice. Although we welcome constructive feedback and differing
            opinions, disrespect toward others or businesses is prohibited. Examples of prohibited
            content include (but is not limited to):
          </span>
        </p>
        <ul className="c19 lst-kix_list_7-0 start">
          <li className="c9 li-bullet-0">
            <span className="c2">
              Abusive, harassing, stalking, threatening or verbally attacking others
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              Defamatory, offensive, obscene, vulgar or content that depicts violence
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">References to upsetting or distressing subjects</span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              Hateful speech targeting race/ethnicity, age, color, creed, religion, gender, sexual
              preference or orientation, nationality or political beliefs
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">Sexually explicit, nude or pornographic images</span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              Unlawful content or references to criminal or illegal activity
            </span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">Violating intellectual property rights</span>
          </li>
          <li className="c6 c16 li-bullet-0">
            <span className="c2">
              Viruses or programs that could damage someone else’s computer
            </span>
          </li>
          <li className="c25 c16 li-bullet-0">
            <span className="c2">Commercial or donation-related solicitations</span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            We reserve the right to remove posts, and ban or suspend members that don’t adhere to or
            repeatedly ignore these guidelines. We may also block a person or business who violate
            these rules or otherwise abuse Squad’s online communities.
          </span>
        </p>

        <p className="c0">
          <span className="c4">Third-parties</span>
        </p>
        <p className="c0">
          <span className="c2">
            Squad is not responsible for and does not endorse third-party websites, or content that
            may be linked to/from our social or online communities. Members are required to review
            the privacy and security practices, and policies of all third-party websites.
          </span>
        </p>

        <p className="c0">
          <span className="c4">Disclaimers</span>
        </p>
        <p className="c0">
          <span className="c2">
            Squad reserves the right to change, remove or deny access to the content on our pages.
            We are not responsible for any consequences you may experience as a result of our
            decisions.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Content posted by the Squad admin team is believed to be reliable at the time it is made
            public. However, we cannot guarantee that information is complete or accurate at all
            times.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Squad is not responsible for anything in the app that isn’t submitted or created by a
            Squad employee, agent or consultant.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            None of the content posted in Squad should be interpreted as financial, medical or
            professional advice.
          </span>
        </p>
      </div>
    </article>
  );
}
