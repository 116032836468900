import { NavLink } from 'react-router-dom';
import scrollToTop from '../../utils/scrollToTop';
import styles from './index.module.scss';
import CommunityStandards from '../../pages/Terms/CommunityStandards';
import getConfig from '../../config';

const { LEGAL_NAME, TAGLINE } = getConfig();

/** FooterLink - Component SubView for footer navigation links */
const FooterLink = ({ title, to, children, ...rest }) => (
  <li title={title}>
    <NavLink
      to={to}
      className={({isActive}) => (isActive ? styles.active : null)}
      onClick={() => scrollToTop()} {...rest}
    >
      {children}
    </NavLink>
  </li>
);

/** Footer - Component View for navigation and legal text */
export default function Footer() {
  return (
    <footer id="footer" className={styles.Footer}>
      <ul>
        <FooterLink title="Home" to="/" exact>
          Home
        </FooterLink>
        <FooterLink title="Privacy Policy" to="/Privacy">
          Privacy<span className={styles.wide}> Policy</span>
        </FooterLink>
        <FooterLink title="Community Guidelines, Terms of Use & Community Standards" to="/Terms">
          Terms<span className={styles.wide}> of Use</span>
        </FooterLink>
        <FooterLink title="Support Resources" to="/Support">
          Support
        </FooterLink>
      </ul>
      <aside title={TAGLINE}>
        {`© ${LEGAL_NAME} ${new Date().getFullYear()}`}
      </aside>
    </footer>
  );
}
