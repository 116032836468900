import { useEffect } from 'react';

/** Video - Component View to add an auto-playing <video> tag
 * @param {?Booean:true} observeToPlayPause - Observe and play when visible, pause when out of view
 * @param {string} id - Be sure to pass for observer to auto-play and pause when within viewport
 * @param {string:URI|ImportedVideo} src
 * @param {?string} className
 * @param {?string} width
 */
export default function Video({ observeToPlayPause = true, id, src, width, ...rest }) {
  useEffect(() => {
    const $vidElm = document.getElementById(id);
    let observer;
    if (observeToPlayPause) {
      /** useIntersectionObserver - Auto play/pause video when its element is within/out of viewport
       * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
       */
      if (!window.IntersectionObserver) return;
      observer = new IntersectionObserver(
        (entries) => entries[0].target[entries[0].isIntersecting ? 'play' : 'pause'](),
        { threshold: 0.1 }, // Safari requires root
      );
      observer.observe($vidElm);
    }
    // eslint-disable-next-line no-empty
    return () => observer?.unobserve($vidElm);
  }, [observeToPlayPause, id]);

  return (
    <video
      id={id || null}
      playsInline
      autoPlay
      preload="metadata"
      muted
      loop
      poster={src.replace('.mp4', '.jpg')}
      width={width || null}
      {...rest}
    >
      <source src={src} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}
