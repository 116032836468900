// import { Link, useLocation } from 'react-router-dom';
import ContactEmail from '../../components/ContactEmail';
import ContactPhone from '../../components/ContactPhone';
import { ReactComponent as Mechanic } from '../../assets/mechanic-dark-skin-tone.min.svg';
import styles from './SupportDetails.module.scss';

/** SupportDetails - Page SubView for "Support" (exported to HTML via .docx)
 * 1. Remove leading <html>, <head>, </head>, <meta> and </html> tags
 * 2. Change <body class> to <div class title="">" (keep classes)
 * 3. Move <style> tag content into SupportDetails.global.scss
 * 4. Replace-All "class=" --> "className="
 * 5. Replace heading <p>+<span> with <h1>
 */
export default function SupportDetails({ id = null }) {
  // const location = useLocation();
  // const layout = location.pathname.charAt(1) === '_' ? '_' : '';

  // Placeholder until we get official page-content from SQUADJJ legal

  return (
    <article className={styles.SupportDetails} id={id} title="Support details">
      <div title="">
        {/* <p>
          <Mechanic className={styles.PersonIllustration} />
        </p>
        <p>
          At Squad we do our best to get it right the first time. But sometimes, apps are gonna app.
        </p> */}
        <p>
          If you’ve encountered an error with Squad’s functionality or need support, contact us at{' '}
          <ContactEmail />
          {' or '}
          <ContactPhone />.
        </p>
        {/* <Link to={`/${layout}Terms`}>Back to Terms of Use</Link> */}
      </div>
    </article>
  );
}
