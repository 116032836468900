import Main from '../../layout/Main';
import SubscriptionsDetails from './SubscriptionsDetails';
// import useScrollToHash from '../../hooks/useScrollToHash';
import classNames from '../../utils/classNames';
import styles from './index.module.scss';

/** Content - Page SubView aggregating content
 * @param {?Boolean} basic - when `true` renders without the Footer component
 */
const Content = ({ basic }) => (
  // useScrollToHash({ hashRouter: true }); // If there are page anchors (see Terms pg)
  <Main
    className={classNames(styles.Subscriptions, 'content')}
    title="Subscriptions details"
    basic={basic}
    logo="dark"
  >
    <SubscriptionsDetails />
  </Main>
);

/** Subscriptions - Page View - NAV NOT NEEDED (uses view below as `default`) */
export default function Subscriptions() {
  return <Content />;
}

/** SubscriptionsBasic - Page View sans navigation links */
export function SubscriptionsBasic() {
  return <Content basic />;
}
