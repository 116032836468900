import Main from '../../layout/Main';
import PrivacyPolicy from './PrivacyPolicy';
// import useScrollToHash from '../../hooks/useScrollToHash';
import classNames from '../../utils/classNames';
import styles from './index.module.scss';

/** Content - Page SubView aggregating content
 * @param {?Boolean} basic - when `true` renders without the Footer component
 */
const Content = ({ basic }) => (
  // useScrollToHash({ hashRouter: true }); // If there are page anchors (see Terms pg)
  <Main className={classNames(styles.Privacy, 'content')} title="Privacy legal" basic={basic} logo="dark">
    <PrivacyPolicy />
  </Main>
);
/** Privacy - Page View */
export default function Privacy() {
  return <Content />;
}

/** PrivacyBasic - Page View sans navigation links */
export function PrivacyBasic() {
  return <Content basic />;
}
