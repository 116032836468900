import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from '@react-hook/window-size';
import Main from '../../layout/Main';
import { AppStoreLinks, Card } from '../../components/Cards';
import Video from '../../components/Video';
import useParallax from '../../hooks/useParallax';
import getDocHeight from '../../utils/getDocHeight';
import scrollToTop from '../../utils/scrollToTop';
import { ReactComponent as Branding } from '../../assets/branding.min.svg';
import getConfig from '../../config';
import styles from './index.module.scss';
import './index.global.scss';

/** Video Sources - (via `.env` file) transcoding service optimized for connection speed
 * @see https://cloudinary.com/documentation/video_manipulation_and_delivery
 */

const { BRANDING, TAGLINE, SRC_PHONE_APP_PREVIEW, SRC_BG_VIDEO } = getConfig();

/** GreenCard - Page SubView */
const GreenCard = ({ isBanner }) => (
  <Card isBanner={isBanner} id="card-green" heading="Explore">
    <p>
      Use the map feature to get directions, or quickly and securely find detailed information about
      local Black businesses.
    </p>
  </Card>
);

/** RedCard - Page SubView */
const RedCard = ({ isBanner }) => (
  <Card isBanner={isBanner} id="card-red" heading="Connect">
    <p>Connect with a community of like-minded people and resources.</p>
  </Card>
);

/** YellowCard - Page SubView */
const YellowCard = ({ isBanner }) => (
  <Card isBanner={isBanner} id="card-yellow" heading="Buy Black">
    <p>
      Quickly find what you’re looking for using our Black-owned and -empowered business directory.
    </p>
  </Card>
);

// /** RSVPLink - Page SubView */
// const RSVPLink = () => (
//   <Link to="LA2022RSVP" onClick={() => scrollToTop()}>
//     <button className="button-rsvp" type="button">
//       RSVP Now
//     </button>
//   </Link>
// );

// /** EventInfo - Page SubView */
// const EventInfo = () => (
//   <div className="event">
//     <h2>Superbowl LVI Launch Event</h2>
//     <h3>February 13, 2022 | Los Angeles, CA</h3>
//     <p>
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus cursus neque ipsum, ipsum.
//       Nibh elementum nulla fermentum viverra vel duis pulvinar a amet. Consequat auctor egestas
//       mauris pellentesque. Fermentum quam lorem volutpat tempus, lacus.
//     </p>
//   </div>
// );

/** Home - Page View */
export default function Home() {
  const [winW, winH] = useWindowSize();
  const [origBgVidW, setOrigBgVidW] = useState(0);
  const [origBgVidH, setOrigBgVidH] = useState(0);
  const [bgVideoWidth, setBgVideoWidth] = useState(0);

  const aspectRatio = { mobile: 9 / 15, tablet: 3 / 4, viewport: winW / winH };
  const isPortraitPhone = winW / winH <= aspectRatio.mobile;
  const isMobile = localStorage.mobile || window.navigator.maxTouchPoints > 1; // THIS IS THE WAY
  const phoneCardPositioning = { from: 'bottom', ...(isMobile ? { move: -108 } : {}) };

  const lowestScrollRatio = 0.751; // The lowest scroll trigger point - aquire from log within hook

  const scrollYPos = useParallax({
    lowestScrollRatio,
    slidesSelector: '[class*="_slide"]:not([class*="_bg__"]',
    elmsMap: {
      // key is element-alias, value is element-id
      logo: 'logo',
      bannerTop1: 'banner-top-1',
      bannerTop2: 'banner-top-2',
      bannerTop3: 'banner-top-3',
      appVideo: 'phone-app-preview',
      greenCard: 'card-green',
      redCard: 'card-red',
      yellowCard: 'card-yellow',
      turquoiseCard: 'card-turquoise',
      bgVideo: 'bg-video',
      bannerEnd: 'banner-end',
      endContent: 'cards-end',
      footer: 'footer',
    },
    fade: [
      // Intro Banners
      //    "Connect"
      { elm: 'bannerTop1', options: { down: 0.006520605112154408, up: 0.15986174119680277 } },
      //    "Explore"
      { elm: 'bannerTop2', options: { down: 0.05216484089723526, up: 0.20954849859580904 } },
      //    "Buy Black"
      { elm: 'bannerTop3', options: { down: 0.0912884715701617, up: 0.2592352559948153 } },
      // Phone and card `up` w/o extra length was 0.5839029153298926
      // Phone App Preview
      { elm: 'appVideo', options: { down: 0.1512205659969756, up: 0.6407823168614744 } },
      // Green Card
      { elm: 'greenCard', options: { down: 0.16202203499675957, up: 0.6407823168614744 } },
      // Red Card
      { elm: 'redCard', options: { down: 0.3348455389933031, up: 0.6407823168614744 } },
      // Yellow Card
      { elm: 'yellowCard', options: { down: 0.5076690429898466, up: 0.6407823168614744 } },
      // Ending Banner
      { elm: 'bannerEnd', options: { down: 0.5857162784209792, up: 0.7015391737067469 } },
      // Final Content
      //    Cards
      { elm: 'endContent', options: { down: 0.7160050920032404, up: lowestScrollRatio } },
      //    Footer
      { elm: 'footer', options: { down: lowestScrollRatio - 0.04, up: lowestScrollRatio } },
    ],
    fix: [
      // Logo
      { elm: 'logo', options: { move: 40, within: 2, posIsRel: false } },
      // Phone App Preview
      {
        elm: 'appVideo',
        options: { ...(isPortraitPhone ? {} : { from: 'middle', c: true }), within: 3 },
      },
      // Green Card
      {
        elm: 'greenCard',
        options: {
          ...(isPortraitPhone ? phoneCardPositioning : { move: 0.19, c: true }),
          within: 3,
        },
      },
      // Red Card
      {
        elm: 'redCard',
        options: {
          ...(isPortraitPhone ? phoneCardPositioning : { move: 0.501, c: true }),
          within: 4,
        },
      },
      // Yellow Card
      {
        elm: 'yellowCard',
        options: {
          ...(isPortraitPhone ? phoneCardPositioning : { move: 0.8157, c: true }),
          within: 5,
        },
      },
      // Background Video
      { elm: 'bgVideo', options: { from: 'bottom', within: 7 } },
    ],
  });

  // MORE PARALLAX EFFECTS: Each `useEffect` separated by dependencies so each only runs when needed

  /** Scroll Arrows - down shown on init */
  useEffect(() => {
    const $arrowUp = document.getElementById('arrow-up');
    const $arrowDown = document.getElementById('arrow-down');
    if (scrollYPos + winH >= getDocHeight() - 300) {
      // Show Up
      $arrowUp.classList.add('scroll-arrow-animate');
      $arrowDown.classList.remove('scroll-arrow-animate');
    } else {
      // Show Down
      $arrowUp.classList.remove('scroll-arrow-animate');
      $arrowDown.classList.add('scroll-arrow-animate');
    }
  }, [winW, winH, scrollYPos]);

  /** Background Video Width - Recalculate if the window is resized or rotates */
  useEffect(() => {
    const $bgVideo = document.getElementById('bg-video');
    //   Get the video's default W/H
    if (!origBgVidW) {
      setOrigBgVidW($bgVideo.clientWidth);
      setOrigBgVidH($bgVideo.clientHeight);
    } else {
      //   The video needs to be scaled to the H of the viewport - we solve for W and H auto-scales
      //   Remember cross-multiplication in Algebra?
      //   Solve for (w)idth: w/WinH = bgVw/bgVh --> bgVh*w = bgVw*WinH --> w = (bgVw*WinH)/bgVh
      let scaledBgVw = (origBgVidW * winH) / origBgVidH;
      if (scaledBgVw < winW) {
        scaledBgVw = winW;
      }
      setBgVideoWidth(scaledBgVw);
      //   We want the video centered no matter how wide it is
      //     Subtract video-W from browser-W, then divide by 2 to get the px to scoot it to the left
      const bgWcentering = scaledBgVw > winW ? (scaledBgVw - winW) / 2 : (winW - scaledBgVw) / 2;
      $bgVideo.style.left = `-${bgWcentering}px`;
    }
  }, [winW, winH, bgVideoWidth, origBgVidW, origBgVidH]);

  /** Footer Positioning - Recalculate if the window is resized or rotates */
  useEffect(() => {
    const $footer = document.getElementById('footer');
    const fullInnerHeight = document.body.scrollHeight + winH;
    // Finding the height of all slides is the same as above (ref)
    //   [...document.querySelectorAll('[class*="_slide"]:not([class*="_bg__"]')].reduce(
    //     (cumulativeHeight, slideElement) => cumulativeHeight + slideElement.offsetHeight,
    //     0, // cumulativeHeight initial value
    //   ) + winH;
    // Positioning: Sum the height of All slide containers + viewport height - footer height
    $footer.style.top = `${fullInnerHeight - $footer.offsetHeight}px`;
  }, [winH]);

  return (
    <>
      <span id="arrow-up" className="scroll-arrow" data-arrow-iconfont="ArrowIconFont" />
      <span id="arrow-down" className="scroll-arrow" data-arrow-iconfont="ArrowIconFont" />

      {/* Background: faces-purple */}
      <div className={styles.slide1}>
        <div className="bg-container">
          <div className={styles.slide1_bg} />
        </div>
        <div>
          <Branding className="branding" />
          <p>
            Squad Beta Testing
          </p>
          <p>
            We are excited to have you as one of the first volunteers to review and offer feedback
            on our new app, Squad. This app is designed to empower the Black community by giving
            everyone access to explore, connect, and buy Black.
          </p>
          <p>
            In this beta testing, you will be able to connect with users, message users, review
            Black-owned businesses, and support one another&mdash;all on one platform. Remember,
            this is a beta version and our team is working diligently to provide the best user
            experience. Your feedback will help improve the app and create the best experience for
            our audience. We appreciate your participation in making this app the best that it can
            be!
          </p>
          <p>
            Remember to <b><em>Explore</em></b>, <b><em>Connect</em></b>, and{' '}
            <b><em>Buy Black</em></b>.
          </p>
        </div>
        {/* <div className="overlay">
          <div data-role="split">
            <Branding className="branding" />
            <p aria-label="decoding">
              \sk&bull;wäd\ <span>noun</span>
            </p>
            <p aria-label="definition">
              Of or relating to a group of like-minded people who elevate each other’s beliefs or
              goals.
            </p>
            <p aria-label="definition">
              A collaborative, participatory space that fosters Black ambition.
            </p>
          </div>
          <div data-role="split">
          </div>
        </div> */}
      </div>
      <Main title={`${BRANDING} | ${TAGLINE}`}>
        {/* Background: faces-normal */}
        <div className={styles.slide2}>
          <div className="bg-container">
            <div className={styles.slide2_bg} />
          </div>
          <div className="overlay">
            <div id="banner-top-1">
              <GreenCard isBanner />
            </div>
            <div id="banner-top-2">
              <RedCard isBanner />
            </div>
            <div id="banner-top-3">
              <YellowCard isBanner />
            </div>
          </div>
        </div>

        <div className={styles.slide3}>
          <div className="overlay">
            <div data-role="split">
              <Video id="phone-app-preview" src={SRC_PHONE_APP_PREVIEW} />
            </div>
            <div data-role="split">
              <GreenCard />
            </div>
          </div>
          <div className="bg-container">
            <div className={styles.slide3_bg} />
          </div>
        </div>

        {/* Background: city */}
        <div className={styles.slide4}>
          <div className="bg-container">
            <div className={styles.slide4_bg} />
          </div>
          <div className="overlay">
            <div data-role="split" />
            <div data-role="split">
              <RedCard />
            </div>
          </div>
        </div>

        {/* Background: faces-normal2 */}
        <div className={styles.slide5}>
          <div className="bg-container">
            <div className={styles.slide5_bg} />
          </div>
          <div className="overlay">
            <div data-role="split" />
            <div data-role="split">
              <YellowCard />
            </div>
          </div>
        </div>

        {/* Background: faces-normal2 */}
        <div className={styles.slide5}>
          <div className="bg-container">
            <div className={styles.slide5_bg} />
          </div>
          <div className="overlay" />
        </div>

        {/* Background: video */}
        <div className={styles.slide6}>
          <div className="bg-container">
            <div className={styles.slide6_bg}>
              <Video id="bg-video" src={SRC_BG_VIDEO} width={bgVideoWidth} />
            </div>
          </div>
          <div className="overlay">
            <div id="banner-end" /*aria-label="You Ready?"*/>
              <p>You Ready?</p>
            </div>
          </div>
        </div>
        <div className={styles.slide7}>
          <div className="overlay">
            <div id="cards-end">
              <div data-role="split">
                {/* <AppStoreLinks /> */}
                {/* <p>
                  <strong>
                    We’re so excited that you’re part of our inaugural family. As one of the first
                    users, we’d love to hear what you think about Squad—the first app designed to
                    {' '}<b>empower</b>, <b>connect</b> and <b>educate</b> the Black community like
                    never before.
                  </strong>
                </p>
                <section>
                  <p>
                    In the testing enviornment, you’ll be able to:
                    <ul>
                      <li>Connect with friends</li>
                      <li>Message someone</li>
                      <li>Review Black-owned businesses</li>
                    </ul>
                  </p>
                </section>
                <p>
                  Remember, this is only the beginning—our team is constantly adding new features
                  to make Squad the coolest app around. We appreciate your feedback and
                  participation!
                </p> */}
              </div>
              {/* <div data-role="split">
                <div>
                  <RSVPLink />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* DEBUG */
        /* {`isMobile: ${isMobile}`}
        <br />
        <br />
        {winW / winH}
        <br />
        {scrollYPos / (getDocHeight() + winH)} */}
      </Main>
    </>
  );
}
