/** useParallax - Custom Hook composed of its own helpers and using shared utils
 * @requires '@react-hook/window-size'
 * @requires /src/hooks/useScrollPosition
 * @requires /src/utils/getDocHeight
 * @interface useParallax
 * @method elmFader
 * @method elmFixedAt
 */

import { useEffect, useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import useScrollPosition from './useScrollPosition';
import getDocHeight from '../utils/getDocHeight';

// HELPERS:

/** elmFader - Parallax movement element fade in/out within a range
 * @param {[Object]} Array of elements and their options:
 *        { elm{string} $elm     - element-alias per passed `elmsMap` [gets cached as `$elmsMap`]
 *          options{Object}      - Scroll-range { down: {Number:%fraction}, up: {Number:%fraction} }
 * @param {Object} config
 *        { $elmsMap{Object}           - key is element-alias, value id pre-cached element
 *          lowestScrollRatio{Number}, - Fraction via `scrollRatio`: The lowest scroll trigger point
 *          winH{Number},
 *          scrollYPos{Number} }
 */
const elmFader = (elmsAndOptions, { $elmsMap, lowestScrollRatio, winH, scrollYPos }) => {
  const scrollRatio = scrollYPos / (getDocHeight() + winH); // Quotient value
  // console.log('scrollYPos', scrollYPos);
  // console.log('scrollRatio', scrollRatio);
  elmsAndOptions.forEach(({ elm, options: { down, up } }) => {
    if (scrollRatio <= down || (scrollRatio > up && !$elmsMap[elm].getAttribute('data-hidden'))) {
      // Fade-Out
      $elmsMap[elm].classList.remove('fadeIn');
      $elmsMap[elm].setAttribute('data-hidden', true);
      $elmsMap[elm].classList.add('fadeOut');
    } else if (
      scrollRatio > down &&
      scrollRatio <= up &&
      $elmsMap[elm].getAttribute('data-hidden')
    ) {
      // Fade-In
      $elmsMap[elm].classList.remove('fadeOut');
      $elmsMap[elm].removeAttribute('data-hidden');
      $elmsMap[elm].classList.add('fadeIn');
    }
    if (scrollRatio > lowestScrollRatio) window.scrollTo(0, document.documentElement.scrollHeight);
  });
};

/** elmFixedAt - Fixed position locking/unlocking at a pixel offset within its container
 * @param {[Object]} Array of elements and their options:
 *        { elm{string} $elm        - element-alias per passed `elmsMap` [gets cached as `$elmsMap`]
 *          options{Object} options - The options for repositioning the element when it sticks
 *           { from{string}         - top/middle positive `px` is down, bottom pos `px` is up
 *             move{Number},        - To `from` reference: Whole-num (px), Fraction (%)
 *             within{Number},      - The number of the slide that the $elm to fix is within
 *             posIsRel{?Boolean},  - Element position is relative to container/viewport
 *             posType{?string}     - CSS `position` values (defaults `absolute` positioning)
 *             c{?Boolean}          - Center of element positioning, or from element top edge
 * @param {Object} config
 *        { $elmsMap{Object}        - key is element-alias, value id pre-cached element
 *          slidesSelector{string}  - Selects slide elements via `document.querySelectorAll()`
 *          winH{Number},
 *          scrollYPos{Number} }
 */
const elmFixedAt = (elmsAndOptions, { $elmsMap, slidesSelector, winH, scrollYPos }) => {
  elmsAndOptions.forEach(
    ({
      elm,
      options: { from = 'top', move = 0, within = 1, posIsRel = true, posType = 'absolute', c },
    }) => {
      if ($elmsMap[elm].getAttribute('data-hidden') !== 'true') {
        const elmHeight = $elmsMap[elm].offsetHeight;
        const allSlidesNo = within < 2 ? 0 : within - 2; // Safety # > 1
        const slideOffsetHeight = [...document.querySelectorAll(slidesSelector)].reduce(
          (cumulativeHeight, slideElement, i, array) => {
            if (i === allSlidesNo) array.splice(1); // Breaks reduce loop
            return cumulativeHeight + slideElement.offsetHeight;
          },
          0, // cumulativeHeight initial value
        );
        // If pixels from top
        const movePx = move > 0 && move < 1 ? winH * move : move; // When >1 a fraction was passed
        const moveOffset = c ? elmHeight / 2 : 0;
        const moveFromBottom = movePx + moveOffset;
        const moveNormal = movePx - moveOffset;
        const pxChange = from === 'bottom' ? moveFromBottom : moveNormal;
        let pxFromTop = pxChange;
        // Origin reference adjustment
        if (from === 'middle')
          pxFromTop = (pxChange < 0 ? winH + pxChange : winH - pxChange) - winH / 2;
        if (from === 'bottom') pxFromTop = winH - elmHeight - pxChange;
        const elmFixed = slideOffsetHeight - scrollYPos <= 0;
        // console.log(
        //   $elmsMap[elm].id,
        //   'from',
        //   from,
        //   // slideOffsetHeight - scrollYPos <= 0,
        //   // elmHeight === winH,
        //   'eH',
        //   elmHeight,
        //   'winH',
        //   winH,
        //   // 'fixed',
        //   // elmFixed,
        //   'pxChange',
        //   pxChange,
        //   // 'e.top',
        //   // $elmsMap[elm].getBoundingClientRect().top,
        //   'pxFromTop',
        //   pxFromTop,
        //   // 'move',
        //   // move,
        //   'scrollYPos',
        //   scrollYPos,
        //   // 'calc',
        //   // slideOffsetHeight - scrollYPos,
        //   // 'slideH',
        //   // slideOffsetHeight,
        // );
        // Element stickiness
        if (elmFixed) {
          $elmsMap[elm].style.position = 'fixed';
          $elmsMap[elm].style.top = `${pxFromTop}px`;
        } else {
          $elmsMap[elm].style.position = posType;
          $elmsMap[elm].style.top = `${
            (posIsRel ? pxFromTop / winH : (winH + pxFromTop) / winH) * 100
          }%`;
        }
      }
    },
  );
};

// CUSTOM HOOK:

/** useParallax - Fade and Lock elements at Scroll-Y ratio percentage points for Responsive UX
 * @param {Number} lowestScrollRatio - Fraction via `scrollRatio`: The lowest scroll trigger point
 * @param {string} slidesSelector    - Selects slide elements via `document.querySelectorAll()`
 * @param {Object} elmsMap           - Element caching: key is element-alias, value is elm id-prop
 * @param {[Object]} fade            - Array of Objects: each elm-alias & options to fade in/out
 * @param {[Object]} fix             - Array of Objects: each elm-alias & options when/where to fix
 */
export default function useParallax({ lowestScrollRatio, slidesSelector, elmsMap, fade, fix }) {
  const scrollYPos = useScrollPosition();
  const [winW, winH] = useWindowSize();
  const [$elmsMap, set$elmsMap] = useState();

  /** Element Stickiness/Fading/Visibility - Run when scrolled and window is resized or rotates */
  useEffect(() => {
    if (!$elmsMap) {
      // Cache the elements once first
      const elementsMap = {};
      Object.keys(elmsMap).forEach((key) => {
        elementsMap[key] = document.getElementById(elmsMap[key]);
      });
      set$elmsMap(elementsMap);
    } else {
      elmFader(fade, { $elmsMap, lowestScrollRatio, winH, scrollYPos });
      elmFixedAt(fix, { $elmsMap, slidesSelector, winH, scrollYPos });
    }
  }, [$elmsMap, elmsMap, lowestScrollRatio, slidesSelector, fade, fix, scrollYPos, winW, winH]);

  return scrollYPos;
}
