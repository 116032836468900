import { Navigate, HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import GetTheApp, { GetTheAppBasic } from '../pages/GetTheApp';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Privacy, { PrivacyBasic } from '../pages/Privacy';
import RSVP, { RSVPBasic } from '../pages/RSVP';
import Subscriptions, { SubscriptionsBasic } from '../pages/Subscriptions';
import Support, { SupportBasic } from '../pages/Support';
import Terms, { TermsBasic } from '../pages/Terms';
// import Search from '../pages/Search';
// import getConfig from '../config';

// const { DEPLOYMENT_BASENAME } = getConfig(); // Not needed for HashRouter

/** Pass query-parameters to page/redirection
 * @example Use this pattern to preserve and pass URI data forward
 *  <Route
 *    path="/PageName"
 *    render={({ location }) => <Redirect to={{ ...location, pathname: '/SameOrDifferentPage' }} />}
 *  />
 */

const CONTENT = {
  la2022rsvp: {
    context: 'la2022rsvp',
    heading: 'RSVP',
    subheading: 'Los Angeles',
    preSubmissionContent: (
      <>
        {/* <p>Interested in attending an upcoming event hosted by the Squad?</p> */}
        <p>RSVP below to be added to the list.</p>
        <p>
          To ensure all of our guests are safe, we require each attendee to present us with either
          their COVID vaccine card or a 72 hour negative COVID test.
        </p>
        <p>
          Please note, this is an ultra exclusive and intimate affair and this invitation is only
          for the recipient. Our host will be checking in and verifying attendees at the entrance.
          Any additional guests who are not on the guest list will not be allowed to enter.
        </p>
      </>
    ),
    textarea: 'How did you hear about the event?',
  },
  waitlist: {
    context: 'waitlist',
    heading: 'Waitlist',
    // subheading: '',
    preSubmissionContent: (
      <>
        <p>We’re updating the app and will soon be adding new businesses to the Squad directory.</p>
        <p>Join the waitlist to have your business added.</p>
      </>
    ),
    // textarea: '',
  },
};

/** AppRouter - Routing scheme for known and unknown pages */
export default function AppRouter({ injectedHooks }) {
  return (
    <Router /*basename={DEPLOYMENT_BASENAME}*/>
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/_Privacy" element={<PrivacyBasic />} />
        <Route path="/Privacy" element={<Privacy />} />

        <Route path="/_Subscriptions" element={<SubscriptionsBasic />} />
        <Route path="/Subscriptions" element={<Subscriptions />} />

        <Route path="/_Support" element={<SupportBasic />} />
        <Route path="/Support" element={<Support />} />

        <Route path="/_Terms" element={<TermsBasic />} />
        <Route path="/Terms" element={<Terms />} />

        <Route
          path="/_IncomingLink"
          render={({ location }) => <Navigate to={{ ...location, pathname: '/_GetTheApp' }} />}
        />
        <Route
          path="/IncomingLink"
          render={({ location }) => <Navigate to={{ ...location, pathname: '/GetTheApp' }} />}
        />
        <Route path="/_GetTheApp" element={<GetTheAppBasic />} />
        <Route path="/GetTheApp" element={<GetTheApp />} />

        {/* la2022rsvp */}
        <Route path="/_la2022rsvp" element={<RSVPBasic {...CONTENT.la2022rsvp} />} />
        <Route path="/la2022rsvp" element={<RSVP {...CONTENT.la2022rsvp} />} />
        {/* waitlist */}
        <Route path="/_waitlist" element={<RSVPBasic {...CONTENT.waitlist} />} />
        <Route path="/waitlist" element={<RSVP {...CONTENT.waitlist} />} />

        {/* <Route path="/Search" element={<Search />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
