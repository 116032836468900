import { useEffect } from 'react';

/** useScrollToHash - Scroll to an anchor on page-load
 * @param {?Boolean} hashRouter - send `true` when using HashRouter instead of normal BrowserRouter
 */
export default function useScrollToHash({ hashRouter } = {}) {
  useEffect(() => {
    setTimeout(() => {
      const { hash } = window.location;
      if (hash) {
        const id = hashRouter ? (hash.split('/')[1] ?? '').split('#')[1] : hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 97); // For Safari
  }, [hashRouter]);
}
